// TODO: replace with actual flags
// active binary flags
export const ARTIFACTORY_GITHUB_WORKFLOW_ACCESS =
  'portal.artifactory.github.workflow.access';
export const CATALOG = 'portal.catalog';
export const SMC_CLOUD_USAGE = 'portal.smc.cloud.usage';
export const ROUTE_THEME = 'portal.route.theme';
export const SMC_INLINE_CREATION = 'portal.smc.inline.creation';
export const NEW_ACTIONS_CATALOG_URL = 'portal.actionscatalog.newurl';
export const VAULT_NEW_UI = 'portal.vault.new.ui';
export const REGISTER_SERVICE_IN_CATALOG = 'portal.register.service.in.catalog';
export const UG_MEMBERSHIP_REQUEST = 'portal.ug.membership.request';
export const SERVICE_CATALOG_USER_ACTIVITY = 'portal.catalog.user.activity';
export const SERVICE_CATALOG_PRODUCTIVITY_DASHBOARD =
  'portal.catalog.productivity.dashboard';
export const GROUP_ATTACHED_PROJECTS_ENABLE = 'portal.group.attached.projects';
export const EMAIL_VALIDATION_ENABLE = 'portal.email.validation';
export const REVIEWABLE_SAAS_LICENSE_USAGE =
  'portal.projects.license.reviewable';
export const ARTIFACTORY_SAAS_CLOUD_USAGE =
  'portal.projects.cloud.artifactory_saas';
export const STOP_GITHUB_AND_SELF_HOSTED_ATLASSIAN =
  'portal.projects.stop.github.and.self.hosted.atlassian';
export const ROVER = 'portal.rover';
export const EXPORT_USERS = 'portal.export-users';
export const DEVEX_TOOL_TOUCAN = 'portal.devex.tool-toucan';
export const DEVEX_PVM = 'portal.devex.pvm';
export const USER_WARNING_VISIBLE = 'portal.user.warning.visible';
export const DEV_FORUMS = 'portal.developer-forums';
export const CHARGEBACK_CATALOG_MIGRATION =
  'portal.chargeback.catalog.migration';
export const CHARGEBACK_URL_V2_ENABLED = 'portal.chargeback.url_v2.enabled';
export const MAX_NUMBER_USER_EMAIL_CHIPS_ENABLE =
  'portal.max.number.user.email.chip';
export const MARKETPLACE_V2 = 'devex.marketplacev2';
export const DYNAMIC_GROUPS_TAGS = 'portal.dynamic.groups.tags';
export const AUTOLOGOUT = 'portal.autologout';

// deprecated binary flags
const PORTAL_TEMP1 = 'portal.temp1';
const PORTAL_TEMP2 = 'portal.temp2';
const PORTAL_TEST = 'portal.test';
const PORTAL_TEST1 = 'test1';
const DEVEX_CATALOG = 'portal.devex.catalog';
const OWNERS_VIEW_MEMBERS = 'portal.owners.view.members';
const VIEW_SHARING_SETTINGS = 'portal.sharing.settings';
const GITHUB_EMU_SUSPENDED = 'portal.githubemu.suspended';
const PROJECT_RESOURCES_PAGE_REVAMP_ENABLE =
  'portal.project.resourcs.page.revamp.enable';
const REFACTORED_RESOURCES_FORM = 'portal.resources.refactored';
const MTFUJI_RENAMING = 'portal.mtfuji.rename';
const PROJECT_USER_GROUPS_PAGE_REVAMP_ENABLE =
  'portal.project.user.groups.page.revamp.enable';
const ARTIFACTORY_SAAS_RESOURCE_PAGES = 'portal.artifactorysaas.resource.pages';
const ARTIFACTORY_SAAS_FOR_OWNERS = 'portal.artifactorysaas.for.owners';
const REMOVE_PREFIX_RESOURCES = 'portal.resources.prefix.removed';
const LEGAL_AGREEMENT_ACCESS = 'portal.project.legalagreement';
const ALLOW_GROUP_MANAGER_TO_ARCHIVE = 'portal.allow.group.manager.to.archive';
const NEW_ASSIGN_RESOURCES_API_UG = 'portal.new.assign.resources.ug';
const ASSIGN_USERGROUPS_TO_RESOURCES = 'portal.assign.usergroups.to.resources';
const RESOURCE_MANAGERS_ENABLE = 'portal.resource.managers';
const ROLE_MANAGEMENT_ENABLE = 'portal.role.management';
const NEW_RESOURCE_MANAGERS_ENABLE = 'portal.new.resource.managers';
const ARTIFACTORY_SAAS_HIDE_RCLASS = 'portal.artifactorysaas.hide.rclass';
const SERVICE_CATALOG_INSIGHTS = 'portal.catalog.insights';
const SMC_WIZARD_ARTIFACTORY_SAAS_ENABLED =
  'portal.smc.wizard.artifactory.saas.enable';

// active canary flags
export const CHANGE_ROLE_ACCESS_DISABLE = 'portal.change.role.access.disable';
export const SERVICE_CATALOG_CONFLUENCE_INSIGHTS_DASHBOARD =
  'portal.catalog.confluence.insights.dashboard';

// deprecated canary flags
const PORTAL_CANARY_TEMP1 = 'portal.temp1.canary';
const PORTAL_CANARY_TEMP2 = 'portal.temp2.canary';

// this will keep history of deleted binary flags after getting removed from initialization.ts
export const TO_BE_DELETED_BINARY_FLAGS = [
  PORTAL_TEMP1,
  PORTAL_TEMP2,
  PORTAL_TEST,
  PORTAL_TEST1,
  OWNERS_VIEW_MEMBERS,
  DEVEX_CATALOG,
  MTFUJI_RENAMING,
  REFACTORED_RESOURCES_FORM,
  VIEW_SHARING_SETTINGS,
  REMOVE_PREFIX_RESOURCES,
  PROJECT_RESOURCES_PAGE_REVAMP_ENABLE,
  PROJECT_USER_GROUPS_PAGE_REVAMP_ENABLE,
  GITHUB_EMU_SUSPENDED,
  ALLOW_GROUP_MANAGER_TO_ARCHIVE,
  ARTIFACTORY_SAAS_RESOURCE_PAGES,
  ARTIFACTORY_SAAS_FOR_OWNERS,
  RESOURCE_MANAGERS_ENABLE,
  ASSIGN_USERGROUPS_TO_RESOURCES,
  NEW_ASSIGN_RESOURCES_API_UG,
  LEGAL_AGREEMENT_ACCESS,
  ARTIFACTORY_SAAS_HIDE_RCLASS,
  NEW_RESOURCE_MANAGERS_ENABLE,
  ROLE_MANAGEMENT_ENABLE,
  SERVICE_CATALOG_INSIGHTS,
  UG_MEMBERSHIP_REQUEST,
  GROUP_ATTACHED_PROJECTS_ENABLE,
  USER_WARNING_VISIBLE,
  EMAIL_VALIDATION_ENABLE,
  SMC_WIZARD_ARTIFACTORY_SAAS_ENABLED,
];

// this will keep history of deleted canary flags after getting removed from initialization.ts
export const TO_BE_DELETED_CANARY_FLAGS = [
  PORTAL_CANARY_TEMP1,
  PORTAL_CANARY_TEMP2,
];
