import React, { useCallback, useEffect, useState } from 'react';
import {
  AADGroup,
  AppEnvs,
  ApplicationEnvValue,
  ApplicationRequestProps,
  KeyValue,
  ServiceAccountAdmin,
} from './types';
import { Control, useFormContext } from 'react-hook-form';
import {
  ControlAutocomplete,
  isAppEnvValueEmpty,
  isOptionalEnvironmentConfigKey,
  normalizeName,
} from './FormHelpers';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { CreateUserGroupDialog } from '../CreateUserGroupDialog';
import { NamespaceAutocompletionResponseProject } from './GithubRepository';
import { UserLabels } from './UserLabels';
import { ServiceAccountAdmins } from './ServiceAccountAdmins';

type EnvironmentConfigurationEditProps = {
  stargateProject: NamespaceAutocompletionResponseProject;
  appName: string;
  envVarName: string;
  envName: string;
  mainEnvName?: string;
  value: ApplicationEnvValue | undefined;
  defaultValue?: ApplicationEnvValue;
  autocompletionsLoading?: boolean;
  groupOptions: AADGroup[];
  onGroupOptionsChange: (groupOptions: AADGroup[]) => void;
  isDefaultEnv?: boolean;
  validationSetter: (isValid: boolean) => void;
};

const ValueState = {
  Inherit: 'inherit',
  Overwrite: 'overwrite',
};

const useStyles = makeStyles(() =>
  createStyles({
    inlineCreate: {
      display: 'flex',
      alignItems: 'center',
    },
    inlineCreateItem: {
      padding: '0 5px 0 0',
      fontWeight: 'bolder',
    },
    inlineCreateLink: {
      cursor: 'pointer',
    },
    inheritedValueContainer: {
      padding: 7,
      '& .values': {
        minHeight: 24,
      },
    },
    inheritedValueHelperText: {
      marginTop: 10,
    },
  }),
);

function isAppEnvObjectValid(
  appEnvs: AppEnvs,
  mainEnvName: string | undefined,
): boolean {
  if (!mainEnvName || !Object.hasOwn(appEnvs, mainEnvName)) {
    return false;
  }
  const envs: { [key: string]: ApplicationEnvValue } = appEnvs[mainEnvName];
  return !Object.keys(envs).some(
    key =>
      !isOptionalEnvironmentConfigKey(key) && isAppEnvValueEmpty(envs[key]),
  );
}

export default function EnvironmentConfigurationEdit({
  stargateProject,
  appName,
  envVarName,
  envName,
  mainEnvName,
  isDefaultEnv,
  value,
  defaultValue,
  autocompletionsLoading,
  groupOptions,
  onGroupOptionsChange,
  validationSetter,
}: EnvironmentConfigurationEditProps) {
  const {
    getValues,
    setValue,
    control,
    formState: { isValid },
  } = useFormContext<ApplicationRequestProps>();

  const [selectedValue, setSelectedValue] = useState<
    ApplicationEnvValue | undefined
  >(value);
  const [valueState, setValueState] = useState(
    isDefaultEnv || !isAppEnvValueEmpty(value)
      ? ValueState.Overwrite
      : ValueState.Inherit,
  );

  const onEnvVarValueChange = useCallback(
    (v: ApplicationEnvValue) => {
      if (envName.length === 0 || envVarName.length === 0) {
        return;
      }
      const oldValue = getValues('app_envs') || {};
      const newValue = { ...oldValue } as any;
      if (isAppEnvValueEmpty(v) && !isDefaultEnv) {
        delete newValue[envName][envVarName];
      } else {
        newValue[envName][envVarName] = v;
      }
      setValue('app_envs', newValue, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [envName, envVarName, getValues, isDefaultEnv, setValue],
  );

  useEffect(() => {
    onEnvVarValueChange(selectedValue || '');
  }, [selectedValue, onEnvVarValueChange]);

  useEffect(() => {
    if (!mainEnvName || mainEnvName.length === 0) {
      return;
    }
    validationSetter(
      isAppEnvObjectValid(getValues('app_envs') || {}, mainEnvName),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const onValueStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValueState: string = event.target.value;
    setValueState(newValueState);
    if (newValueState === ValueState.Inherit) {
      setSelectedValue(undefined);
    } else {
      setSelectedValue(v => (isAppEnvValueEmpty(v) ? defaultValue : v));
    }
  };

  const valueStateControl = isDefaultEnv !== true && (
    <RadioGroup row value={valueState} onChange={onValueStateChange}>
      <FormControlLabel
        value={ValueState.Inherit}
        control={<Radio />}
        label={`Inherit from ${normalizeName(mainEnvName)}`}
      />
      <FormControlLabel
        value={ValueState.Overwrite}
        control={<Radio />}
        label={`Overwrite for ${normalizeName(envName)}`}
      />
    </RadioGroup>
  );

  switch (envVarName) {
    case 'aad_readonly_groups':
    case 'aad_readwrite_groups':
      return (
        <>
          {valueStateControl}
          <AADGroupEditForm
            stargateProject={stargateProject}
            appName={appName}
            mainEnvName={mainEnvName}
            envVarName={envVarName}
            control={control}
            disabled={valueState === ValueState.Inherit}
            value={
              (valueState === ValueState.Inherit
                ? defaultValue
                : selectedValue) as AADGroup[]
            }
            autocompletionsLoading={autocompletionsLoading}
            options={groupOptions}
            onChange={setSelectedValue}
            onOptionsChange={onGroupOptionsChange}
          />
        </>
      );
    case 'service_account_admins':
      return (
        <>
          {valueStateControl}
          <ServiceAccountAdmins
            key={envVarName}
            currentEnvName={envName}
            onChange={setSelectedValue}
            disabled={valueState === ValueState.Inherit}
            validationSetter={validationSetter}
            value={
              (valueState === ValueState.Inherit
                ? defaultValue
                : selectedValue) as ServiceAccountAdmin[]
            }
          />
        </>
      );
    case 'user_labels':
      return (
        <>
          {valueStateControl}
          <UserLabels
            key={envVarName}
            onChange={setSelectedValue}
            disabled={valueState === ValueState.Inherit}
            validationSetter={validationSetter}
            value={
              (valueState === ValueState.Inherit
                ? defaultValue
                : selectedValue) as KeyValue
            }
          />
        </>
      );
    default:
      return <div>Edit Environment Configurations</div>;
  }
}

type EditFormProps = {
  stargateProject: NamespaceAutocompletionResponseProject;
  appName: string;
  envVarName: string;
  mainEnvName?: string;
  disabled: boolean;
  control?: Control<ApplicationRequestProps>;
  autocompletionsLoading?: boolean;
};

function AADGroupEditForm({
  stargateProject,
  value,
  options,
  control,
  appName,
  mainEnvName,
  envVarName,
  disabled,
  autocompletionsLoading,
  onChange,
  onOptionsChange,
}: {
  value: AADGroup[];
  options: AADGroup[];
  onChange: (value: AADGroup[]) => void;
  onOptionsChange: (options: AADGroup[]) => void;
} & EditFormProps) {
  const [userGroupDialogOpen, setUserGroupDialogOpen] =
    useState<boolean>(false);

  const classes = useStyles();

  const groupsFilter = createFilterOptions({
    stringify: (o: AADGroup) => `${o.name}${o.uuid}`,
  });

  const handleUserGroupCreate = () => {
    setUserGroupDialogOpen(true);
  };

  const handleAADGroupDialogClose = (_?: string, option?: any) => {
    if (option) {
      onChange([...value, option]);
      onOptionsChange([...options, option]);
    }
    setUserGroupDialogOpen(false);
  };

  return (
    <>
      {disabled && (
        <FormControl fullWidth>
          <InputLabel>{normalizeName(envVarName)}</InputLabel>
          <Paper
            elevation={0}
            variant="outlined"
            className={classes.inheritedValueContainer}
          >
            <Box className="values">
              {value.map(item => (
                <Chip size="small" label={item.name} key={item.uuid} />
              ))}
            </Box>
          </Paper>
          <FormHelperText className={classes.inheritedValueHelperText}>
            <span className={classes.inlineCreate}>
              <span className={classes.inlineCreateItem}>Create a new one</span>{' '}
              or select the existing groups to add to{' '}
              {normalizeName(envVarName)}.
            </span>
          </FormHelperText>
        </FormControl>
      )}
      {!disabled && (
        <ControlAutocomplete
          name="app_envs"
          rules={{
            required: true,
            validate: v => isAppEnvObjectValid(v as AppEnvs, mainEnvName),
          }}
          control={control}
          multiple
          loading={autocompletionsLoading}
          options={options}
          onChange={(_, v) => onChange(v)}
          value={value}
          getOptionLabel={v => v.name}
          filterOptions={groupsFilter}
          getOptionSelected={(o, v) => o.uuid === v.uuid}
          renderOption={o => o.name || o.uuid}
          fullWidth
          renderInput={params => {
            return (
              <TextField
                {...params}
                label={normalizeName(envVarName)}
                placeholder="Select one or more groups from the list"
                helperText={
                  <span className={classes.inlineCreate}>
                    <Link
                      className={`${classes.inlineCreateLink} ${classes.inlineCreateItem}`}
                      onClick={() => handleUserGroupCreate()}
                    >
                      Create a new one
                    </Link>{' '}
                    or select the existing groups to add to{' '}
                    {normalizeName(envVarName)}.
                  </span>
                }
                fullWidth
              />
            );
          }}
        />
      )}
      <CreateUserGroupDialog
        appName={appName}
        open={userGroupDialogOpen}
        handleDialogClose={handleAADGroupDialogClose}
        stargateProject={stargateProject}
        groupType="app_envs"
      />
    </>
  );
}
