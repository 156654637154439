import React from 'react';
import { usgTheme } from 'usg-theme';
import { usgNewTheme } from 'usg-new-theme';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@internal/plugin-catalog-fork';

import * as plugins from './plugins';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import SentryInitializer from './components/monitoring/Sentry';
import { Root } from './components/Root';
import { RootElementCreator } from './RootElementCreator';
import {
  AlertDisplay,
  AutoLogout,
  OAuthRequestDialog,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { WelcomePagePage } from '@internal/plugin-welcome-page';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { NewsPage } from '@internal/plugin-news';
import {
  ProfilePage,
  ProjectsPage,
  CreateProject,
  PendingRequestPage,
  CreateResourcePage,
  ViewEditResourcePage,
  ProjectOwnerPage,
  CreateUserGroups,
  ViewEditProject,
  ProjectDetailsPage,
  UserManagmentDetailsPage,
  UserGroupSharingSettings,
  ViewUserGroups3,
  AttachedUserGroupDetailPage,
} from '@internal/plugin-projects';
import { SignInPage } from './SignInPage';
import {
  microsoftAuthApiRef,
  useApi,
  configApiRef,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { stringify as stringifyQueryString } from 'qs';
import {
  generateHeaders,
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
} from 'sg-utils-frontend';
import { BinaryFeatureFlagged, CustomErrorPage } from '@internal/sg-ui-kit';
import {
  TechDocsIndexPage,
  TechDocsReaderPage,
} from '@internal/plugin-techdocs-fork';
import { MtfujiPage } from '@internal/plugin-mtfuji';
import { NotFoundError } from '@backstage/errors';
import {
  ActionsCatalogPage,
  ActionsCatalogItemPage,
  ActionsCatalogPageV2,
} from '@internal/plugin-actions-catalog';
import {
  FeatureFlagsPage,
  featureFlagsPlugin,
} from '@internal/plugin-feature-flags';
import {
  ProjectContractsPage,
  AssignUpdateProjectContract,
} from '@internal/plugin-projects';
import {
  RegisterServicePage,
  catalogManagerPlugin,
} from '@internal/plugin-catalog-manager';
import MUIXLicense from './components/licenses/MUIXLicense';
// TODO: To be used for migration in the future.
// import { unifiedTheme } from '@internal/sg-ui-kit';
// import { UnifiedThemeProvider } from '@backstage/theme';
import { StargateInsightsPage } from '@internal/plugin-stargate-insights';
import { BootErrorPage } from './components/BootErrorPage';
import { ProtectedRoute } from '@internal/plugin-projects';
import { RoverPage } from '@internal/backstage-plugin-rover';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { techDocsPage } from './components/techdocs/TechDocsPage';
import { LightBox } from '@backstage/plugin-techdocs-module-addons-contrib';
import { CustomTechDocsHome } from './components/techdocs/CustomTechDocsHome';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { DevToolsPage } from '@backstage/plugin-devtools';
import {
  DevForumsLandingPage,
  DevForumsQuestionPage,
  DevForumsQuestionFormPage,
  DevForumsQuestionEditFormPage,
} from '@internal/backstage-plugin-dev-forums-frontend';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import {
  AUTOLOGOUT,
  IDLE_TIMEOUT_MINUTES,
  PROMPT_BEFORE_IDLE_SECOND,
} from 'usg-types';

const authProviders = [
  {
    id: 'microsoft-auth-provider',
    title: 'AzureAD',
    message: 'Sign in',
    icon: 'assets/microsoft.svg',
    apiRef: microsoftAuthApiRef,
  },
];

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      registerService: catalogManagerPlugin.routes.registerService,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(plugins.NewsPlugin.externalRoutes, {
      home: plugins.WelcomePagePlugin.routes.root,
    });
    bind(featureFlagsPlugin.externalRoutes, {
      home: plugins.WelcomePagePlugin.routes.root,
    });
  },
  components: {
    SignInPage: props => {
      const configApi = useApi(configApiRef);
      return (
        <SignInPage
          {...props}
          providers={[...authProviders]}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            if (
              typeof (await identityApi?.getCredentials()).token !== 'undefined'
            ) {
              const token = (await identityApi.getCredentials()).token;
              // According to the documentation, https://github.com/backstage/backstage/blob/master/contrib/docs/tutorials/authenticate-api-requests.md
              // it should be identity. Why are we using token only here?
              const userId = sessionStorage.getItem('userId') || '';

              // set local language first time only if application is getting visited first time
              // or language setting has expired
              if (getLocalStorageWithExpiry('locale') === null) {
                const lang = navigator.language;
                const splitLang = lang ? lang.split('-')[0] : 'en';

                // set expiry of language setting to 1 year
                const localeExpiryDate = new Date();
                localeExpiryDate.setFullYear(
                  localeExpiryDate.getFullYear() + 1,
                );
                setLocalStorageWithExpiry(
                  'locale',
                  splitLang === 'ja' ? splitLang : 'en',
                  localeExpiryDate,
                );
              }

              if (!userId) {
                const params = {
                  search_term: [(await identityApi.getProfileInfo()).email],
                };
                const backendUrl = configApi.getString('backend.baseUrl');
                const requestUrl = new URL(
                  `${backendUrl}/api/projects/getGroups/users/search`,
                );
                requestUrl.search = stringifyQueryString(params);
                const headers = generateHeaders({
                  Authorization: `Bearer ${token}`,
                });
                const response = await fetch(requestUrl.toString(), {
                  credentials: 'include',
                  headers: Object.fromEntries(headers),
                });
                const data = await response.json();
                if (data && data.error) {
                  throw new NotFoundError(data.error.message);
                }

                if (!response.ok) {
                  throw new NotFoundError(response.statusText);
                }

                if (data?.response?.data?.value[0]?.id) {
                  const b = Buffer.from(data?.response?.data?.value[0]?.id);
                  // If we don't use toString(), JavaScript assumes we want to convert the object to utf8.
                  // We can make it convert to other formats by passing the encoding type to toString().
                  const s = b.toString('base64');
                  // var encodedString = Buffer.from(data?.response?.data?.value[0]?.id, 'base64');
                  sessionStorage.setItem('userId', s);
                }
                const code = data?.response?.status;
                if (
                  code === 400 ||
                  code === 401 ||
                  code === 404 ||
                  code === 403
                ) {
                  const error = data?.response?.data?.message;
                  // errorApi.post(new Error(`${error}`));
                  // eslint-disable-next-line no-console
                  console.log('error', error);
                }
              }
            }
            // Forward results
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
    BootErrorPage: BootErrorPage,
  },
  themes: [
    {
      id: 'usg',
      title: 'USG Theme',
      variant: 'light',
      Provider: ({ children }) => (
        // Note: This is the theme for the unified theme.
        // To be used for future. There is still much to be fixed for
        // the unified provider.  But the way to fix this is to be
        // able to show in storybook.
        // <UnifiedThemeProvider theme={unifiedTheme}>
        //   {children}
        // </UnifiedThemeProvider>

        // The current theme below will be deprecated.
        // In the future once all storybook has been implemented for each pages.
        <ThemeProvider theme={usgTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="welcome-page" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/register-service" element={<RegisterServicePage />} />
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <SearchContextProvider
        initialState={{
          term: '',
          filters: {},
          types: ['techdocs'],
        }}
      >
        <CustomTechDocsHome />
      </SearchContextProvider>
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      {techDocsPage}
      <TechDocsAddons>
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/welcome-page" element={<WelcomePagePage />} />
    <Route path="/news" element={<NewsPage />} />
    <Route path="/projects" element={<ProjectsPage />} />
    <Route path="/create-project" element={<CreateProject />} />
    <Route
      path="/projects/:projectId/users/:email"
      element={<UserManagmentDetailsPage />}
    />
    <Route path="/projects/:projectId" element={<ProjectDetailsPage />} />
    <Route path="/projects/request" element={<ProjectOwnerPage />} />
    <Route
      path="/projects/:projectId/create-resource"
      element={
        <RootElementCreator themeToUse={usgNewTheme}>
          <CreateResourcePage />
        </RootElementCreator>
      }
    />
    <Route
      path="projects/:projectId/projectmanagement"
      element={<ViewEditProject />}
    />
    <Route
      path="/projects/:projectId/contracts"
      element={<ProjectContractsPage />}
    />
    <Route
      path="/projects/:projectId/contracts/assign"
      element={<AssignUpdateProjectContract />}
    />
    <Route
      path="/projects/:projectId/contracts/:contractId/edit"
      element={<AssignUpdateProjectContract />}
    />
    <Route path="projects/projectmanagement" element={<ViewEditProject />} />
    <Route
      path="/projects/:projectId/userGroups"
      element={<CreateUserGroups />}
    />
    <Route
      path="/projects/:projectId/usergroup/:groupId"
      element={<ViewUserGroups3 />}
    />
    <Route
      path="/projects/:projectId/attachedusergroups/:groupId"
      element={<AttachedUserGroupDetailPage />}
    />
    <Route
      path="/projects/:projectId/usergroup/:groupId/sharingsettings"
      element={<UserGroupSharingSettings />}
    />
    <Route
      path="/projects/:projectId/manageresource/:resourceId"
      element={<ViewEditResourcePage />}
    />
    <Route
      path="/pending-user-membership-requests"
      element={<PendingRequestPage />}
    />
    <Route path="/mtfuji" element={<MtfujiPage />} />
    <Route path="/profile" element={<ProfilePage />} />
    <Route path="/actions-catalog" element={<ActionsCatalogPage />} />
    <Route path="/actions-catalog-v2" element={<ActionsCatalogPageV2 />} />
    <Route
      path="/actions-catalog/:catalogKey"
      element={<ActionsCatalogItemPage />}
    />
    <Route path="/stargate-insights" element={<StargateInsightsPage />} />
    <Route
      path="/feature-flags"
      element={
        <ProtectedRoute redirectTo="/welcome-page">
          <FeatureFlagsPage />
        </ProtectedRoute>
      }
    />
    <Route path="/rover" element={<RoverPage />} />
    <Route path="/qeta" element={<DevForumsLandingPage />} />
    <Route
      path="/qeta/questions/:questionId"
      element={<DevForumsQuestionPage />}
    />
    <Route
      path="/qeta/questions/create"
      element={<DevForumsQuestionFormPage />}
    />
    <Route
      path="/qeta/questions/:questionId/edit"
      element={<DevForumsQuestionEditFormPage />}
    />
    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route
      path="/*"
      element={<CustomErrorPage status="404" statusMessage="" />}
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <MUIXLicense />
    <SentryInitializer />
    <AlertDisplay />
    <OAuthRequestDialog />
    <BinaryFeatureFlagged withFlag={AUTOLOGOUT}>
      <AutoLogout
        idleTimeoutMinutes={IDLE_TIMEOUT_MINUTES}
        promptBeforeIdleSeconds={PROMPT_BEFORE_IDLE_SECOND}
      />
    </BinaryFeatureFlagged>
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
