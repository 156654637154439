import {
  configApiRef,
  createApiFactory,
  createPlugin,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';

import { rootRouteRef } from './routes';
import {
  CloudUsageApi,
  LicenseUsageApi,
  cloudUsageApiRef,
  licenseUsageApiRef,
} from './apis';

export { CloudLicenseUsage, CloudUsage } from './components';

export { CatalogUsagePage } from './pages';

export const chargebackPlugin = createPlugin({
  id: 'chargeback',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: cloudUsageApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        featureFlagsApi: featureFlagsApiRef,
      },
      factory: ({ configApi, identityApi, featureFlagsApi }) =>
        new CloudUsageApi({ configApi, identityApi, featureFlagsApi }),
    }),
    createApiFactory({
      api: licenseUsageApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        featureFlagsApi: featureFlagsApiRef,
      },
      factory: ({ configApi, identityApi, featureFlagsApi }) =>
        new LicenseUsageApi({ configApi, identityApi, featureFlagsApi }),
    }),
  ],
});
