import { Config } from '@backstage/config';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { stringify as stringifyQueryString } from 'qs';
import { CHARGEBACK_URL_V2_ENABLED } from 'usg-types';

export const cloudUsageApiRef = createApiRef<CloudUsageApiInterface>({
  id: 'plugin.chargeback.cloudusage',
});

type CloudUsageApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
  featureFlagsApi: any;
};

export interface IArtifactoryStorageData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
  order_by?: string;
  order?: string;
}
export interface IObsLogsData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
}
export interface IObsMetricsData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
}

export interface CloudUsageApiInterface {
  getArtifactoryCostUsage(
    id: any,
    idToken: Object,
    paramsData: any,
  ): Promise<any>;
  getArtifactorySaaSCostUsage(
    id: any,
    idToken: Object,
    paramsData: any,
  ): Promise<any>;
  getObsLogsCostUsage(id: any, idToken: Object, paramsData: any): Promise<any>;
  getObsMetricsCostUsage(
    id: any,
    idToken: Object,
    paramsData: any,
  ): Promise<any>;
  getGitHubUsageByRepos(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ): Promise<any>;
  getGitHubUsageByRunners(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ): Promise<any>;
  getGHEUsageByRunners(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ): Promise<any>;
  getGHEUsageByRepos(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ): Promise<any>;
  getMulticloudCostUsage(
    id: any,
    idToken: Object,
    paramsData: any,
  ): Promise<any>;
}

export class CloudUsageApi implements CloudUsageApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private backendUrl: string;
  private featureFlagsApi: any;

  constructor(options: CloudUsageApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.backendUrl = `${this.configApi.getString(
      'backend.baseUrl',
    )}/api/chargeback`;

    this.featureFlagsApi = options.featureFlagsApi;
  }

  private async isChargebackURLV2Enabled() {
    const chargebackURLv2EnabledResponse =
      await this.featureFlagsApi.getBinaryFlag(CHARGEBACK_URL_V2_ENABLED);

    return chargebackURLv2EnabledResponse?.data === true;
  }

  public async getObsLogsCostUsage(
    id: any,
    idToken: Object,
    paramsData?: IObsLogsData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/observability-logs/project/${id}`,
    );
    requestUrl.search = stringifyQueryString(paramsData);

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'X-Chargeback-API-Version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getObsMetricsCostUsage(
    id: any,
    idToken: Object,
    paramsData?: IObsMetricsData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/observability-metrics/project/${id}`,
    );
    requestUrl.search = stringifyQueryString(paramsData);

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-chargeback-api-version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getArtifactoryCostUsage(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/artifactory-storage/project/${id}`,
    );
    requestUrl.search = stringifyQueryString(paramsData);

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-chargeback-api-version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getArtifactorySaaSCostUsage(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/art-saas-storage/project/${id}`,
    );
    requestUrl.search = stringifyQueryString(paramsData);

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-chargeback-api-version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getGitHubUsageByRepos(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/github-runners/project/${id}/getGitHubUsageByRepos`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-chargeback-api-version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getGitHubUsageByRunners(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/github-runners/project/${id}/getGitHubUsageByRunners`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-chargeback-api-version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getGHEUsageByRepos(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/github-runners/project/${id}/getGHEUsageByRepos`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-chargeback-api-version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getGHEUsageByRunners(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/github-runners/project/${id}/getGHEUsageByRunners`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-chargeback-api-version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getMulticloudCostUsage(
    id: any,
    idToken: Object,
    paramsData?: IArtifactoryStorageData,
  ) {
    const chargebackURLv2Enabled = await this.isChargebackURLV2Enabled();

    const apiVersion = chargebackURLv2Enabled ? 'v2' : 'v1';
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${this.backendUrl}/smc-usage/project/${id}`);
    requestUrl.search = stringifyQueryString(paramsData);

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-chargeback-api-version': apiVersion,
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }
}
