import React from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
  DialogContent,
  Dialog,
} from '@material-ui/core';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { useForm } from 'react-hook-form';
import { definitions } from '../../api';

type NamespaceAutocompletionResponseProject =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ApplicationEnvAADGroup = definitions['handlers.AADGroup'];

type UserGroupInput = {
  user_group_name: string;
  project_description: string;
};

type CreateUserGroupDialogProps = {
  open: boolean;
  handleDialogClose: (
    selectedTargetForm?: string,
    data?: ApplicationEnvAADGroup,
  ) => void;
  stargateProject: NamespaceAutocompletionResponseProject;
  groupType: string;
  appName: string;
};

export const getDefaultReadWriteGroupName = (appName: string) => {
  return `${appName}-rw`;
};

export const getDefaultReadOnlyGroupName = (appName: string) => {
  return `${appName}-ro`;
};

export const CreateUserGroupDialog = (props: CreateUserGroupDialogProps) => {
  const { open, handleDialogClose, stargateProject, groupType, appName } =
    props;
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);

  const defaultGroupValues: Record<string, UserGroupInput> = {
    developer_group: {
      // the default app name already prefixes the project key
      // but if users create their own group names
      // the form needs to prefix the project key
      // so for the form's default value we need
      // to remove the prefix before we pass it in
      user_group_name: getDefaultReadWriteGroupName(appName).startsWith(
        `${stargateProject.key.toLowerCase()}-`,
      )
        ? getDefaultReadWriteGroupName(appName).substring(
            `${stargateProject.key}-`.length,
          )
        : getDefaultReadWriteGroupName(appName),
      project_description: `Grants members read-write access to ${appName} namespaces`,
    },
    readonly_group: {
      user_group_name: getDefaultReadOnlyGroupName(appName).startsWith(
        `${stargateProject.key.toLowerCase()}-`,
      )
        ? getDefaultReadOnlyGroupName(appName).substring(
            `${stargateProject.key}-`.length,
          )
        : getDefaultReadOnlyGroupName(appName),
      project_description: `Grants members read-only access to ${appName} namespaces`,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
    getFieldState,
  } = useForm<UserGroupInput>({
    mode: 'onChange',
    defaultValues: defaultGroupValues[groupType],
  });

  const isValidName = (name: string) => {
    return stargateProject.key.length + name.length <= 31;
  };

  const onSubmit = async (data: UserGroupInput) => {
    const idToken = await authref.getIdToken();
    const userData: any = await projectApi.getUserDetails({ idToken: idToken });
    let fine = true;
    // Create the User Group
    const userGroupResp: any = await projectApi.addProjectUserGroupData(
      {
        idToken: idToken,
        members: [''],
        name: `${stargateProject.key.toLowerCase()}-${data.user_group_name.toLowerCase()}`,
        description: data.project_description,
        accessible_resources: [],
      },
      stargateProject.id,
    );

    if (userGroupResp.response?.status >= 400) {
      const errorMsg = userGroupResp?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
      fine = false;
    }
    // Add the user to the newly created User Group
    const membershipResp: any =
      await projectApi.createUserMembershipRequestsV2Data(
        {
          idToken: idToken,
          user_memberships: [
            {
              user_email: userData.response.data.email,
              user_group_id: Number(userGroupResp.response?.data?.id),
              action: 'ADD',
            },
          ],
          project_id: stargateProject.id,
        },
        stargateProject.id.toString(),
      );

    if (membershipResp.response?.status >= 400) {
      const errorMsg = membershipResp?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
      fine = false;
    }

    if (fine) {
      reset();
      handleDialogClose(groupType, userGroupResp.response?.data);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleDialogClose()} maxWidth="sm">
      <DialogContent
        id="ug-create-confirmed-dialog"
        style={{ fontWeight: 'bold' }}
      >
        <div style={{ paddingBottom: '24px' }}>
          <h1>Create User Group</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <InputLabel error={!isValid} htmlFor="user_group_name">
                User Group Name*
              </InputLabel>
              <TextField
                style={{ paddingBottom: '0%' }}
                type="text"
                id="user_group_name"
                defaultValue={defaultGroupValues[groupType]?.user_group_name}
                error={!isValid && getFieldState('user_group_name').isTouched}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      style={{
                        textTransform: 'lowercase',
                        marginLeft: '0',
                      }}
                      position="end"
                    >
                      {`${stargateProject.key.toLowerCase()}-`}
                    </InputAdornment>
                  ),
                }}
                helperText={`Provide a unique name for your user group. Only lower case letters, numbers and single-hyphens are allowed. Does not end with hyphen. Max. no. of characters allowed = 32 (including project key - ${`${stargateProject.key.toLowerCase()}-`})`}
                {...register('user_group_name', {
                  required: true,
                  pattern: /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/,
                  validate: isValidName,
                })}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <InputLabel htmlFor="project_description">Description</InputLabel>
              <TextField
                type="text"
                id="project_description"
                defaultValue={
                  defaultGroupValues[groupType]?.project_description
                }
                minRows={4}
                multiline
                fullWidth
                helperText="Provide a brief description of the user group you are creating. "
                placeholder="Example : To manage users who need developer rights to various software tools and are a part of the TPM project."
                {...register('project_description')}
              />
            </div>
            <div style={{ paddingTop: '5%' }}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                  <Button
                    variant="outlined"
                    type="reset"
                    onClick={() => {
                      reset();
                      handleDialogClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={1}>
                  <Button
                    id="create-ug-submit-button"
                    variant="contained"
                    type="submit"
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
