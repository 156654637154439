import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { sortBy } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { Progress } from '@backstage/core-components';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import {
  usePermissions,
  useServiceAccountApi,
} from '@internal/plugin-projects';
import { PageLayout, SGEmailChipInput, Tabs } from '@internal/sg-ui-kit';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Alert from '@material-ui/lab/Alert';

import {
  devToolSupportsDynamicGroup,
  filterDynamicUserGroups,
  getAllRoles,
  isDynamicGroup,
  isResourceUserGroupAssignable,
} from 'sg-utils-frontend';
import {
  ALL_ROLES,
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_GITHUB_WORKFLOW_ACCESS,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  DYNAMIC_GROUPS_TAGS,
  EMAIL_REGEX,
  GITHUBEMU_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  INVALID_EMAIL_ERROR,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  REGEX_ARTIFACTORY_SAAS,
  REGEX_GITHUB_EMU,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_NAME_REGEX,
  SPECIAL_RESOURCE_NAME_REGEX,
  VAULT_DEV_TOOL_ID,
} from 'usg-types';
import { projectApiRef } from '../../../api';
import { ProjectOwners } from '../../../pages/ProjectDetails/ProjectOwners';
import { rootRouteRef } from '../../../routes';
import { ArtifactoryGitHubWorkflowAccessViewTable } from '../artifactory-github-workflow-access/ArtifactoryGitHubWorkflowAccessViewTable';

import {
  fetchAndSetArtifactoryProperties,
  updateArtifactoryProperties,
  validateArtifactoryPropertiesPostUserInput,
} from '../artifactory-github-workflow-access/ArtifactoryService';
import { UserGroupsTab } from '../UserGroupsTab/UserGroupsTab';
import ViewEditResourceForm from '../view-edit-components';

import { useStyles } from '../styles';

const ADMIN_ONLY_DEV_TOOL_IDS: number[] = [];
const COMMON_EDITABLE_DEV_TOOL_IDS = [
  JIRA_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  ARTIFACTORY_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
];

type UserGroupObject = {
  id: number;
  name: string;
  rule_based: boolean;
  role: string;
};
type SortedUserGroupsArrayType = UserGroupObject[];

interface OldViewEditResourceComponentProps {
  projectId: string;
  resourceId: string;
}

export const OldViewEditResourceComponent = ({
  projectId,
  resourceId,
}: OldViewEditResourceComponentProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const rootLink = useRouteRef(rootRouteRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);

  const [dataData, setdataData] = useState<any>(undefined);
  const [resourceData, setResourceData] = useState(null as any);
  const [resourceRoles, setResourceRoles] = useState([] as any);
  const [environmentsData, setEnvironmentsData] = useState([] as any);
  const [editMode, setEditMode] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState('Shareable URL');
  const [resourceID, setResourceID] = useState('');
  const [nameDirty, setNameDirty] = useState(false);
  const [descriptionDirty, setDescriptionDirty] = useState(false);
  const [idTokenData, setIdTokenData] = useState([] as any);
  const [openEditSucessMsg, setOpenEditSucessMsg] = useState(false);
  const [enableResourceBtn, setEnableResourceBtn] = useState(false);
  const [openArcive, setOpenArchive] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [unarchiveBtn, setUnarchiveBtn] = useState(false);
  const [unArchiveOpen, setUnArchiveOpen] = useState(false);
  const [unArchiveSuccessDialog, setUnArchiveSuccessDialog] = useState(false);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const [assignedUserGroups, setAssignedUserGroups] = useState([]);
  const [supportsDynamicGroups, setSupportsDynamicGroups] = useState(false);
  const [
    showArtifactoryGithubWorkflowAccess,
    setShowArtifactoryGithubWorkflowAccess,
  ] = React.useState(false);
  const [existingArtifactoryProperties, setExistingArtifactoryProperties] =
    useState(null as any);
  const [artifactoryProperties, setArtifactoryProperties] = useState(
    null as any,
  );
  const [isLoadingArtifactoryProperties, setIsLoadingArtifactoryProperties] =
    useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [resourceManagerCheck, setResourceManagerCheck] = useState(false);
  const [resourceManagerData, setResourceManagerData] = useState([]);
  const [userEmailErrorData, setUserEmailErrorData] = React.useState({
    message: '',
  });
  const [isAddUserEnable, setIsAddUserEnable] = useState(true);
  const [isAddUserLoading, setIsAddUserLoading] = useState(false);
  const [groupsWithExcludedTags, setGroupsWithExcludedTags] = useState<any>([]);
  const {
    isAdmin,
    isAnyAdmin,
    roles,
    isProjectOwner,
    isLoading: isLoadingPermissions,
    error: permissionsError,
  } = usePermissions();
  const {
    accountToken,
    isLoading: isLoadingAuthToken,
    getServiceToken,
  } = useServiceAccountApi();
  const [isTagsFlag, setIsTagsFlag] = useState(false);

  const readOnlyResources = [VAULT_DEV_TOOL_ID, MTFUJI_DEV_TOOL_ID];

  const tagConfig: any = useMemo(() => {
    return {
      [GITHUBEMU_DEV_TOOL_ID]: {
        excluded: ['artifactorysaas', 'selfhosted'],
        allowed: 'githubemu',
      },
      [ARTIFACTORY_SAAS_DEV_TOOL_ID]: {
        excluded: ['githubemu', 'selfhosted'],
        allowed: 'artifactorysaas',
      },
      default: {
        excluded: ['githubemu', 'artifactorysaas'],
        allowed: 'selfhosted',
      },
    };
  }, []);

  const backToTarget = projectId ? `/projects/${projectId}` : rootLink();
  const backToLink = useMemo(
    () => ({
      to: backToTarget,
      label: projectId ? 'Back to Project' : 'Back to Projects',
    }),
    [backToTarget, projectId],
  );

  const [{ loading: fetchResourceManagersLoading }, fetchResourceManagers] =
    useAsyncFn(async email => {
      const token = await authref.getIdToken();
      const res: any = await projectApi.getGroupManagersOfUserGroup(
        projectId,
        resourceId,
        token,
      );
      if (res?.status === 200) {
        const project_resource_managers = res?.data?.project_resource_managers;
        const sortedEmails: any = sortBy(project_resource_managers, [
          'user_email',
        ]);
        setResourceManagerData(sortedEmails);
        const resourceManagerRole = res?.data?.project_resource_managers?.some(
          (obj: any) => obj.user_email === email,
        );
        if (resourceManagerRole) {
          setEnableResourceBtn(resourceManagerRole);
          setResourceManagerCheck(resourceManagerRole);
        } else {
          setResourceManagerCheck(false);
        }
      } else {
        const errorMsg = res?.data?.error?.message;
        errorApi.post(new Error(`${errorMsg}`));
        navigate(`/projects/${projectId}`);
      }
    }, []);

  // To get perticluar project deatils based on projectId
  const [{ loading, error }, fetchContent] = useAsyncFn(async () => {
    const idToken = await authref.getIdToken();
    const params = {
      manipulators: [
        'resources',
        `resource_${resourceId}`,
        'is_combined',
        'user_groups',
      ],
    };
    setIdTokenData(idToken);

    try {
      const data = await projectApi.getProjectByID(projectId, idToken, params);

      setdataData(data);

      const currentResource = data?.resources[0];

      const userId = sessionStorage.getItem('userId') || '{}';

      if (userId) {
        const userDetailsParams = {
          idToken: idToken,
        };
        const queryParams = {
          include: 'all',
        };
        // getting admin role

        try {
          const res: any = await projectApi.getUserDetails(
            userDetailsParams,
            queryParams,
          );
          const userData = res?.response?.data;
          setUserEmail(userData?.email);
          setEnableResourceBtn(isAdmin || isProjectOwner(Number(projectId)));
          await fetchResourceManagers(userData?.email);
          if (currentResource?.deleted_on) {
            setUnarchiveBtn(true);
          }
          const allRoles = getAllRoles(userData, projectId);
          if (allRoles && !allRoles.some(role => ALL_ROLES.includes(role))) {
            navigate(`/projects/${projectId}`);
          }
        } catch (err) {
          errorApi.post(new Error(`${err?.message}`));
        }

        // getting Development tools data
        let devtoold: any = await projectApi.getDevelopmentToolsData({
          idToken: idToken,
          ...params,
        });
        devtoold = devtoold.response.data.development_tools;
        devtoold = devtoold.find(
          (dt: any) => dt.id === currentResource?.dev_tool_id,
        );
        const resRoles = devtoold?.roles;
        setResourceData(currentResource);
        setResourceRoles(resRoles ? resRoles : []);
        setSupportsDynamicGroups(devToolSupportsDynamicGroup(devtoold));
        if (
          currentResource?.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID ||
          currentResource?.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID
        ) {
          const artifactName =
            currentResource?.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID
              ? 'artifactory_self_hosted'
              : 'artifactory_saas';
          const featureFlagResponseForArtifactoryGHWorkflow =
            await featureFlagsApi.getBinaryFlag(
              ARTIFACTORY_GITHUB_WORKFLOW_ACCESS,
            );
          setShowArtifactoryGithubWorkflowAccess(
            featureFlagResponseForArtifactoryGHWorkflow.data,
          );
          if (featureFlagResponseForArtifactoryGHWorkflow.data) {
            // Call fetchAndSetArtifactoryProperties with the necessary parameters
            await fetchAndSetArtifactoryProperties(
              data,
              projectId,
              authref,
              projectApi,
              artifactName,
              setArtifactoryProperties,
              setExistingArtifactoryProperties,
              setIsLoadingArtifactoryProperties,
            );
          }
        }
      }

      const Arr: any[] = [];
      Arr.push(data?.resources[0]?.linkedResources);
      Arr[0]?.push(data?.resources[0]);
      setEnvironmentsData(Arr[0]);
    } catch (err) {
      errorApi.post(new Error(`${err?.message}`));
    }
  }, [isLoadingPermissions]);

  // get assigned user groups
  const [{ loading: ugLoading }, fetchAssignedUserGroups] =
    useAsyncFn(async () => {
      try {
        const token = await authref.getIdToken();
        const res: any = await projectApi.getUserGroupsOfResource(
          projectId,
          resourceId,
          token,
          {},
        );

        let userGroupsData = res?.data.user_groups_roles;

        const groupIdToObjectMapping: any = {};
        const userGroupsArray = dataData?.user_groups;
        userGroupsArray.forEach((group: any) => {
          if (!(group.id in groupIdToObjectMapping))
            groupIdToObjectMapping[group.id] = group;
        });

        userGroupsData = userGroupsData.map((group: any) => {
          const groupData = groupIdToObjectMapping[group.id];

          if (!groupData) {
            throw new Error(
              `Couldn't find group ${group.id} under project ${projectId}`,
            );
          }

          return {
            ...group,
            name: groupData.name,
            rule_based: groupData.rule_based,
            archived: groupData.deleted_on ? true : false,
          };
        });
        setAssignedUserGroups(userGroupsData);
      } catch (err) {
        errorApi.post(new Error(`${err?.message}`));
        navigate(`/projects/${projectId}`);
      }
    }, [dataData, roles, userEmail]);

  useEffect(() => {
    (async () => {
      if (!isLoadingPermissions) {
        await fetchContent();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPermissions]);

  // get all user groups with excluded tags so we can filter them out
  const getGroupsWithExcludedTags = useCallback(async () => {
    const devtool = resourceData?.dev_tool_id;
    const { excluded, allowed } = tagConfig[devtool] || tagConfig.default;

    const params = {
      project_id_filters: [parseInt(projectId, 10)],
      entity_type_filters: ['USER_GROUP'],
      tag_filters: [
        {
          tag_key: 'platform-limit-tool',
          tag_values: excluded,
        },
      ],
      include_tags_in_response: true,
      size: 100,
    };

    let currentPage = 1;
    const excludedGroups: any[] = [];
    let hasNextPage = true;

    while (hasNextPage) {
      const res: any = await projectApi.getTagsOfEntity(accountToken.token, {
        ...params,
        page: currentPage,
      });

      if (res?.error) {
        if (res?.error?.code === 401) {
          await getServiceToken();
          await getGroupsWithExcludedTags();
        } else {
          errorApi.post(new Error(`${res?.error?.message}`));
          break;
        }
      }

      const taggedEntities = res?.data.tagged_entities ?? [];
      excludedGroups.push(...taggedEntities);

      hasNextPage = !!res?.data?.next_page;
      currentPage++;
    }

    return excludedGroups.filter(
      (tag: any) => !tag.tags['platform-limit-tool'].includes(allowed),
    );
  }, [
    accountToken,
    resourceData,
    getServiceToken,
    projectId,
    projectApi,
    tagConfig,
    errorApi,
  ]);

  useEffect(() => {
    (async () => {
      if (dataData) fetchAssignedUserGroups();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataData]);

  useEffect(() => {
    (async () => {
      const isTagsRes = await featureFlagsApi.getBinaryFlag(
        DYNAMIC_GROUPS_TAGS,
      );
      setIsTagsFlag(isTagsRes?.data);
    })();
  }, [featureFlagsApi]);

  useEffect(() => {
    (async () => {
      if (
        !isLoadingAuthToken &&
        accountToken.token &&
        resourceData?.dev_tool_id &&
        isTagsFlag
      ) {
        const getGroupsWithExcludedTagsRes = await getGroupsWithExcludedTags();
        setGroupsWithExcludedTags(getGroupsWithExcludedTagsRes);
      }
    })();
  }, [
    isLoadingAuthToken,
    accountToken,
    resourceData,
    getGroupsWithExcludedTags,
    isTagsFlag,
  ]);

  const sortedUserGroupsArray: SortedUserGroupsArrayType = useMemo(() => {
    const userGroupsArray = dataData?.user_groups;

    let filteredUserGroup = userGroupsArray?.filter((ug: any) => {
      return (
        !ug.deleted_on &&
        !assignedUserGroups?.some((fug: any) => fug.id === ug.id)
      );
    });

    if (supportsDynamicGroups) {
      // TODO: tags: remove feature flag
      if (isTagsFlag) {
        filteredUserGroup = filteredUserGroup?.filter((el: any) => {
          return groupsWithExcludedTags?.every((f: any) => {
            return f.entity_id !== el.id;
          });
        });
      } else {
        // show all groups except saas
        if (resourceData?.dev_tool_id === GITHUBEMU_DEV_TOOL_ID) {
          filteredUserGroup = filterDynamicUserGroups(
            filteredUserGroup,
            REGEX_GITHUB_EMU,
            'name',
            true,
          );
          // show all groups except emu
        } else if (resourceData?.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID) {
          filteredUserGroup = filterDynamicUserGroups(
            filteredUserGroup,
            REGEX_ARTIFACTORY_SAAS,
            'name',
            true,
          );
        }
      }
    } else {
      filteredUserGroup = filteredUserGroup?.filter(
        (ug: any) => !isDynamicGroup(ug),
      );
    }

    return filteredUserGroup;
  }, [
    dataData,
    supportsDynamicGroups,
    assignedUserGroups,
    groupsWithExcludedTags,
    resourceData,
    isTagsFlag,
  ]);

  const onSaveResource = async (values: any) => {
    setApiLoader(true);
    const dev_tool_ids = [
      JIRA_DEV_TOOL_ID,
      CONFLUENCE_DEV_TOOL_ID,
      JAMA_DEV_TOOL_ID,
    ];
    const nameParam = values.resourceName;
    const params: any = {
      idToken: idTokenData,
      key: values.resourceKey,
      name: dev_tool_ids.includes(resourceData?.dev_tool_id)
        ? values.resourceName.trim()
        : nameParam,
      description: values.resourceDescription,
    };
    if (
      [ARTIFACTORY_DEV_TOOL_ID, ARTIFACTORY_SAAS_DEV_TOOL_ID].includes(
        resourceData?.dev_tool_id,
      ) &&
      showArtifactoryGithubWorkflowAccess
    ) {
      const validation = validateArtifactoryPropertiesPostUserInput(
        artifactoryProperties,
      );
      if (!validation.isValid) {
        const errorMsg = validation.msg;
        errorApi.post(new Error(`${errorMsg}`));
        setApiLoader(false);
        return;
      }
    }
    const data: any = await projectApi.updateProjectResource(
      projectId,
      resourceId,
      params,
    );
    const resourceCode = data?.response?.status;
    if (resourceCode >= 400) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
      setApiLoader(false);
    }
    if (resourceCode === 200) {
      setResourceData(data?.response?.data);
      setApiLoader(false);
      const inputEmails = [];
      for (const email of values?.addResourceManagers) {
        inputEmails.push({ user_email: email.value.trim() });
      }
      const idToken = await authref.getIdToken();
      const resourceManagersparams = {
        resource_managers: inputEmails,
      };
      setApiLoader(true);
      const addResourceData: any = await projectApi.addGroupManagers(
        projectId,
        resourceId,
        idToken,
        resourceManagersparams,
      );
      const code = addResourceData?.status;
      if (code === 200 || code === 201) {
        setApiLoader(false);
        setOpenEditSucessMsg(true);
      } else {
        setApiLoader(false);
        setUserEmailErrorData({
          ...userEmailErrorData,
          message: addResourceData?.data?.error?.message,
        });
        const errorMsg = addResourceData?.data?.error?.message;
        errorApi.post(new Error(`${errorMsg}`));
      }
    }

    if (
      (resourceData?.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID ||
        resourceData?.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID) &&
      showArtifactoryGithubWorkflowAccess
    ) {
      const artifactName =
        resourceData?.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID
          ? 'artifactory_self_hosted'
          : 'artifactory_saas';
      await updateArtifactoryProperties(
        resourceData,
        projectApi,
        idTokenData,
        projectId,
        artifactName,
        values.resourceKey,
        artifactoryProperties,
      );
    }
  };

  const handleClose = () => {
    setOpenEditSucessMsg(false);
    setEditMode(false);
    fetchContent();
    fetchResourceManagers(userEmail);
  };

  const handleUnarchiveUserResource = () => {
    setUnArchiveOpen(true);
  };

  const handleUnArchiveSuccessClose = () => {
    setUnArchiveSuccessDialog(false);
    navigate(`/projects/${projectId}`);
  };

  const onDescriptionClick = () => {
    setDescriptionDirty(true);
  };

  const handleUnArchiveDialog = async () => {
    const data: any = await projectApi.unArchiveResources(
      projectId,
      Number(resourceId),
      {
        idToken: idTokenData,
      },
    );
    const code = data?.response?.status;
    if (code === 200) {
      setUnArchiveSuccessDialog(true);
    } else if (code === 401 || code === 404) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
  };

  if (
    loading ||
    ugLoading ||
    fetchResourceManagersLoading ||
    isLoadingPermissions ||
    isLoadingAuthToken
  ) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(`${error?.message}`));
    navigate(`/projects/${projectId}`);
  }

  if (permissionsError) {
    errorApi.post(permissionsError);
  }

  const onOpenArchiveDialog = () => {
    setOpenArchive(true);
  };

  const onHandleArchive = async () => {
    const data: any = await projectApi.archiveProjectResource(
      projectId,
      resourceId,
      {
        idToken: idTokenData,
      },
    );
    const code = data?.response?.status;
    if (code === 403 || code === 401 || code === 404) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
    navigate(`/projects/${projectId}`);
  };

  // Calling the delete user group api
  const onCloseArchiveDialog = () => {
    onHandleArchive();
  };

  const enableCommonEditBtn = (dev_tool_id: any) => {
    let editable_tools = COMMON_EDITABLE_DEV_TOOL_IDS;
    if (isAdmin) {
      editable_tools = [...editable_tools, ...ADMIN_ONLY_DEV_TOOL_IDS];
    }
    return editable_tools.includes(dev_tool_id);
  };

  const enableCommonForUnArchiveBtn = (dev_tool_id: any) => {
    let editable_tools = COMMON_EDITABLE_DEV_TOOL_IDS;
    if (isAdmin) {
      editable_tools = [...editable_tools, ...ADMIN_ONLY_DEV_TOOL_IDS];
    }
    return editable_tools.includes(dev_tool_id);
  };

  const onCancelResource = () => {
    if (
      [ARTIFACTORY_DEV_TOOL_ID, ARTIFACTORY_SAAS_DEV_TOOL_ID].includes(
        resourceData?.dev_tool_id,
      ) &&
      showArtifactoryGithubWorkflowAccess
    ) {
      setArtifactoryProperties(existingArtifactoryProperties);
    }
  };

  const disableSaveBtn = (
    resourceName: string,
    resourceDescription: string,
  ) => {
    let minLength = 1;
    let maxLength = 256;
    let regex = RESOURCE_NAME_REGEX;
    let descriptionMaxLength = RESOURCE_DESCRIPTION_MAX_LENGTH;

    // update rules based on dev tool
    switch (resourceData?.dev_tool_id) {
      case GITHUBEMU_DEV_TOOL_ID:
      case GITHUB_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.github;
        maxLength = RESOURCE_NAME_MAX_LENGTH.github;
        regex = SPECIAL_RESOURCE_NAME_REGEX.github;
        descriptionMaxLength = GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH;
        break;
      case CONFLUENCE_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.confluence;
        maxLength = RESOURCE_NAME_MAX_LENGTH.confluence;
        break;
      case JIRA_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.jira;
        maxLength = RESOURCE_NAME_MAX_LENGTH.jira;
        break;
      case ARTIFACTORY_DEV_TOOL_ID:
      case ARTIFACTORY_SAAS_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.artifactory;
        maxLength = RESOURCE_NAME_MAX_LENGTH.artifactory;
        regex = SPECIAL_RESOURCE_NAME_REGEX.artifactory;
        break;
      case JAMA_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.jama;
        maxLength = RESOURCE_NAME_MAX_LENGTH.jama;
        regex = SPECIAL_RESOURCE_NAME_REGEX.jama;
        break;
      default:
        minLength = 1;
        maxLength = 256;
        regex = RESOURCE_NAME_REGEX;
        break;
    }

    // resource name length check
    const resourceNameLengthCheck =
      resourceName.length < minLength || resourceName.length > maxLength;

    // regex format check
    const resourceNameRegexCheck = !resourceName.match(regex);

    // description length check
    const resourceDescriptionLengthCheck =
      resourceDescription.length > descriptionMaxLength;

    return (
      resourceNameLengthCheck ||
      resourceNameRegexCheck ||
      resourceDescriptionLengthCheck ||
      apiLoader
    );
  };
  const resourceManagerList = [] as any;
  resourceManagerData.map((res: any) =>
    resourceManagerList.push({
      value: res.user_email,
      isValid: true,
    }),
  );

  const tabs = [
    {
      label: 'ASSIGNED USER GROUPS',
      value: 'assigned_usergroups',
      content: (
        <div>
          {unarchiveBtn ? (
            <Alert severity="info" id="no-permission-alert">
              <div>
                UserGroups list is not available for archived resources.
              </div>
            </Alert>
          ) : (
            <UserGroupsTab
              fetchUserGroupResources={fetchAssignedUserGroups}
              attachedUserGroups={assignedUserGroups}
              resourceData={{
                ...resourceData,
                roles:
                  resourceData?.dev_tool_id === VAULT_DEV_TOOL_ID
                    ? []
                    : [...resourceRoles],
              }}
              projectId={projectId}
              attachableUserGroups={sortedUserGroupsArray}
              loadingFetchUserGroupResources={ugLoading}
              isAdmin={isAnyAdmin}
              isOwner={isProjectOwner(Number(projectId))}
              isResourceArchived={unarchiveBtn}
              resourceManagerCheck={resourceManagerCheck}
            />
          )}
        </div>
      ),
    },
  ];

  const resourceManagerErrorMessages = (dev_tool_id: any) => {
    switch (dev_tool_id) {
      case ARTIFACTORY_SAAS_DEV_TOOL_ID:
        return (
          <div>
            Resource update request submitted, please wait 2hrs whilst we update
            it to your project workspace.
          </div>
        );
      default:
        return (
          <div>
            Resource update request submitted, please wait whilst we update it
            to your project workspace.
          </div>
        );
    }
  };

  const ResourceManagerFieldView = () => {
    return (
      <>
        <h4 className={classes.labelHeader}>Resource Managers</h4>
        {resourceManagerData?.length === 0 && <p>Not Specified</p>}
        <p>
          <Grid container spacing={2}>
            {resourceManagerData?.map((res: any) => {
              return (
                <Grid item xs={5}>
                  {res.user_email}
                </Grid>
              );
            })}
          </Grid>
        </p>
      </>
    );
  };

  const checkUserValidity = async (email: string) => {
    try {
      const token = await authref.getIdToken();
      const isValidRes = await projectApi.checkIfUserIsValid(
        projectId,
        email,
        token,
      );
      return isResponseStatus2XX(isValidRes);
    } catch (e) {
      return false;
    }
  };

  const checkAddUserValidity = async (email: string) => {
    const isAddUserValid = await checkUserValidity(email);
    setIsAddUserEnable(isAddUserValid && isAddUserEnable);

    return isAddUserValid;
  };

  return (
    <>
      <PageLayout
        type="entity"
        title={dataData?.name || ''}
        headerAdditionalControls={
          <ProjectOwners owners={dataData?.owners || null} />
        }
        backToLink={backToLink}
        children={undefined}
      />
      {apiLoader ? <Progress /> : ''}
      <div className={classes.container}>
        <Formik
          initialValues={{
            resourceKey: resourceData?.key,
            resourceName: resourceData?.name,
            resourceDescription: resourceData?.description
              ? resourceData.description
              : '',
            addResourceManagers: resourceManagerList,
          }}
          onSubmit={() => {}}
          enableReinitialize
        >
          {formik => {
            return (
              <>
                <Form onKeyDown={e => e.key === 'Enter' && e.preventDefault()}>
                  <Grid container>
                    <Grid item xs={9}>
                      {unarchiveBtn && (
                        <Chip
                          label="Archived Resource"
                          variant="outlined"
                          className={classes.archivedChip}
                        />
                      )}
                      <ViewEditResourceForm
                        classes={classes}
                        devToolId={resourceData?.dev_tool_id}
                        formik={formik}
                        isEdit={editMode}
                        resourceData={resourceData}
                        roles={roles}
                        resourceSpecificProps={{
                          artifactoryProperties,
                          copyButtonLabel,
                          dataData,
                          descriptionDirty,
                          environmentsData,
                          nameDirty,
                          onDescriptionClick,
                          projectId,
                          resourceID,
                          setArtifactoryProperties,
                          setCopyButtonLabel,
                          setEnvironmentsData,
                          setNameDirty,
                          setResourceID,
                          showArtifactoryGithubWorkflowAccess,
                        }}
                      />
                    </Grid>
                    {unarchiveBtn &&
                    isAdmin &&
                    enableCommonForUnArchiveBtn(resourceData?.dev_tool_id) ? (
                      <Grid item xs={3} className={classes.btnAlign}>
                        <Button
                          variant="contained"
                          onClick={handleUnarchiveUserResource}
                        >
                          UnArchive
                        </Button>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {!editMode && enableResourceBtn && !unarchiveBtn ? (
                      <>
                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                          {enableCommonEditBtn(resourceData?.dev_tool_id) ||
                          resourceManagerCheck ? (
                            <Button
                              id="edit-resource-button"
                              data-testid="edit-resource-button"
                              variant="contained"
                              onClick={() => {
                                setEditMode(true);
                              }}
                            >
                              EDIT
                            </Button>
                          ) : (
                            ''
                          )}
                          {isAdmin ||
                          (isProjectOwner(Number(projectId)) &&
                            !readOnlyResources.includes(
                              resourceData?.dev_tool_id,
                            )) ||
                          resourceManagerCheck ? (
                            <Button
                              variant="outlined"
                              onClick={() => onOpenArchiveDialog()}
                              style={{ marginLeft: '8px' }}
                              id="archive-resource-button"
                            >
                              ARCHIVE
                            </Button>
                          ) : null}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                  {!readOnlyResources.includes(resourceData?.dev_tool_id) &&
                    (editMode ? (
                      <Grid>
                        <h4 className={classes.labelHeader}>
                          Resource Manager(s)
                        </h4>
                        <Field
                          name="addResourceManagers"
                          data-testid="resource-managers-field"
                          className={classes.resourceManagerGrid}
                          component={SGEmailChipInput}
                          required
                          variant="outlined"
                          patternMatch={EMAIL_REGEX}
                          helperText="Enter user emails to assign resource managers. Press
                        enter or space key to add email id to the list. Press
                        ‘Save’ to save the changes."
                          errorText={INVALID_EMAIL_ERROR}
                          checkValidity={checkAddUserValidity}
                          handleDeleteCallBack={(isError: boolean) => {
                            setIsAddUserEnable(!isError);
                          }}
                          handleBeforeAddCallBack={(isError: boolean) => {
                            setIsAddUserEnable(!isError);
                          }}
                          handleLoadingCallBack={(addUserloading: boolean) => {
                            setIsAddUserLoading(addUserloading);
                          }}
                          handleBlurInputCallBack={(isError: boolean) => {
                            setIsAddUserEnable(!isError);
                          }}
                        />
                      </Grid>
                    ) : (
                      <ResourceManagerFieldView />
                    ))}
                  {editMode ? (
                    <Grid
                      container
                      spacing={2}
                      className={classes.saveDivSection}
                    >
                      <Grid item xs={1}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            formik.resetForm({});
                            setEditMode(false);
                            onCancelResource();
                            setUserEmailErrorData({
                              ...userEmailErrorData,
                              message: '',
                            });
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={1} style={{ paddingLeft: '3%' }}>
                        <Button
                          id="edit-resource-submit-button"
                          data-testid="edit-resource-submit-button"
                          variant="contained"
                          type="submit"
                          disabled={
                            disableSaveBtn(
                              formik.values.resourceName,
                              formik.values.resourceDescription,
                            ) ||
                            isAddUserLoading ||
                            !isAddUserEnable
                          }
                          onClick={() => onSaveResource(formik.values)}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                  {!editMode &&
                  resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID ? (
                    <>
                      <div className={classes.shareableUrl}>
                        <Link
                          to={`${resourceData?.url}`}
                          target="_blank"
                          style={{
                            fontWeight: 'bold',
                            textDecorationLine: 'underline',
                            color: '#307FFD',
                            marginBottom: '28px',
                          }}
                        >
                          <span style={{ display: 'flex' }}>
                            {resourceData?.url}
                            <OpenInNewIcon
                              fontSize="small"
                              style={{ marginLeft: '8px' }}
                            />
                          </span>
                        </Link>
                        <div>
                          <Button
                            variant="outlined"
                            style={{ marginLeft: '24px' }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${window.location.protocol}//${window.location.hostname}/projects/${projectId}?resource=${resourceId}&path=`,
                              );
                              setCopyButtonLabel('COPIED');
                              setTimeout(() => {
                                setCopyButtonLabel('Shareable URL');
                              }, 2000);
                            }}
                            disabled={copyButtonLabel === 'COPIED'}
                          >
                            {copyButtonLabel}{' '}
                            <FileCopyIcon
                              style={{ marginLeft: '8px' }}
                              fontSize="small"
                            />
                          </Button>
                          <FormHelperText
                            style={{ marginLeft: '24px', padding: '0 24px' }}
                          >
                            Click to copy resource URL.
                          </FormHelperText>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  {!editMode &&
                    [
                      ARTIFACTORY_DEV_TOOL_ID,
                      ARTIFACTORY_SAAS_DEV_TOOL_ID,
                    ].includes(resourceData?.dev_tool_id) &&
                    showArtifactoryGithubWorkflowAccess && (
                      <Grid item xs={9}>
                        <ArtifactoryGitHubWorkflowAccessViewTable
                          name={resourceData?.name}
                          artifactoryProperties={artifactoryProperties}
                          isLoading={isLoadingArtifactoryProperties}
                        />
                      </Grid>
                    )}

                  <Dialog
                    open={unArchiveOpen}
                    onClose={() => setUnArchiveOpen(false)}
                    maxWidth="xs"
                    style={{ top: '5' }}
                    className={classes.dialog}
                  >
                    <DialogContent style={{ fontWeight: 'bold' }}>
                      Are you sure you want to unarchive this resource?
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setUnArchiveOpen(false)}
                      >
                        cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleUnArchiveDialog}
                      >
                        UnArchive
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={unArchiveSuccessDialog}
                    onClose={handleUnArchiveSuccessClose}
                    maxWidth="xs"
                    style={{ top: '5' }}
                    className={classes.dialog}
                  >
                    <DialogContent style={{ fontWeight: 'bold' }}>
                      Resource has been successfully unarchived. It might take a
                      few minutes to reflect in the project details page.
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleUnArchiveSuccessClose}
                      >
                        Ok
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={openEditSucessMsg}
                    onClose={handleClose}
                    maxWidth="xs"
                    style={{ top: '5' }}
                    className={classes.dialog}
                  >
                    <DialogContent
                      id="resource-update-confirmed-dialog"
                      style={{ fontWeight: 'bold' }}
                    >
                      {resourceManagerErrorMessages(resourceData?.dev_tool_id)}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                      <Button
                        id="dialog-close-button"
                        variant="contained"
                        size="small"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={openArcive}
                    onClose={() => setOpenArchive(!openArcive)}
                    maxWidth="xs"
                    style={{ top: '5' }}
                    className={classes.dialog}
                  >
                    <DialogContent
                      id="resource-archive-confirmation-dialog"
                      style={{ fontWeight: 'bold' }}
                    >
                      Are you sure you want to archive this resource?
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setOpenArchive(!openArcive)}
                      >
                        cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={onCloseArchiveDialog}
                        id="dialog-resource-archive-button"
                      >
                        Archive
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Form>
              </>
            );
          }}
        </Formik>
        {!editMode && isResourceUserGroupAssignable(resourceData) && (
          <Tabs tabs={tabs} />
        )}
      </div>
    </>
  );
};
