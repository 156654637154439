import { useState } from 'react';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { createGlobalState, useEffectOnce } from 'react-use';

const useGlobalCompanies = createGlobalState<[]>([]);

export function useCompaniesApi() {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);

  const [allCompanies, setAllCompanies] = useGlobalCompanies();
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState<undefined | boolean>();

  const fetchCompanies = async (params: Object, queryParams?: Object) => {
    const companiesRes: any = await projectApi.getCompanyData(
      params,
      queryParams,
    );
    if (companiesRes?.response?.status !== 200) {
      throw new Error(
        `Status Code ${companiesRes?.response?.status}. Failed to fetch companies api: ${companiesRes?.response?.data?.message}`,
      );
    }
    return companiesRes?.response?.data;
  };

  const appendCompanies = async () => {
    const idToken = await authref.getIdToken();
    const params = {
      idToken: idToken,
    };

    // fetch first page to get the number of pages in next_page
    const companiesRes = await fetchCompanies(params);
    let companiesArr = companiesRes?.companies;

    if (companiesRes?.hasOwnProperty('next_page')) {
      const queryParams = {
        page: 2, // since we fetched the first page we start from the second page
      };
      const numberOfPages = companiesRes?.next_page;
      // we loop through the page and fetch each one
      for (let i = 2; i <= numberOfPages; i++) {
        const fetchedCompanies = await fetchCompanies(params, queryParams);
        const companiesList = fetchedCompanies?.companies;
        companiesArr = [...companiesArr, ...companiesList];
        queryParams.page = fetchedCompanies.page + 1;
      }
    }
    return companiesArr;
  };

  useEffectOnce(() => {
    if (allCompanies?.length === 0) {
      setIsLoading(true);
      (async () => {
        try {
          const companiesArr = await appendCompanies();
          setAllCompanies(companiesArr);
          setIsSuccess(true);
        } catch (err) {
          setMessage(err?.message);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setIsLoading(false);
      setIsSuccess(true);
    }
  });

  return { allCompanies, isSuccess, message, isLoading };
}
