import { USGColorScheme } from './types';

export const USG_COLOR_SCHEME: USGColorScheme = {
  primary: {
    red: '#EB0A1E',
    blue: '#0E4295',
    white: '#FFFFFF',
    black: '#000000',
    gray: '#58595B',
    blue50: '#1976d2',
  },
  secondary: {
    darkGray: '#1A2122',
    slateGray: '#8c8d8f',
    mediumGray: '#C4C4C4',
    silverGray: '#E6E6E6',
    lightGray: '#F7F7F7',
    blue: '#1059CD',
    lightBlue: '#1059CD',
    darkRed: '#CC0000',
    redberry: '#8C0000',
    green: '#00a40e',
    orange: '#f48b00',
    grey50: '#A2A9B0',
  },
};
