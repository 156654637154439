import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
} from 'usg-types';
import { useStyles } from '../styles';

interface Props {
  onResourceSubmit: (values: any) => Promise<void>;
  nameDirty: boolean;
  projectData: any;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
  handleCreateResource: () => void;
}
export const CreateGithubResourceForm = ({
  onResourceSubmit,
  nameDirty,
  projectData,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
  handleCreateResource,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          github_organization_name: projectData?.key?.toLowerCase(),
          github_organization_description: '',
        }}
        onSubmit={onResourceSubmit}
      >
        {formik => (
          <Form>
            <Paper className={classes.formContainer}>
              <Grid item xs={6} className={classes.selectedResourceItem}>
                <Typography
                  variant="subtitle2"
                  className={classes.selectedResourceText}
                >
                  Github Organization
                </Typography>
                <p>
                  This will create an organization on Stargate's GitHub
                  Enterprise instance. Unless you have a specific need to use
                  GitHub Enterprise, please consider using the GitHub (EMU)
                  option to create an organization on GitHub EMU instead.
                </p>
                <InputLabel
                  htmlFor="github_organization_name"
                  style={{
                    color:
                      (formik.values.github_organization_name?.length <
                        RESOURCE_NAME_MIN_LENGTH.github &&
                        nameDirty) ||
                      (formik.values.github_organization_name?.length >
                        RESOURCE_NAME_MAX_LENGTH.github &&
                        nameDirty)
                        ? 'red'
                        : 'black',
                  }}
                  className={classes.inputLabelText}
                >
                  Github Organization Name*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="github_organization_name"
                  name="github_organization_name"
                  required
                  error={
                    (formik.values.github_organization_name?.length <
                      RESOURCE_NAME_MIN_LENGTH.github &&
                      nameDirty === true) ||
                    (!formik.values.github_organization_name?.match(
                      '^([a-z0-9]+-)*[a-z0-9]+$',
                    ) &&
                      nameDirty) ||
                    (formik.values.github_organization_name?.length >
                      RESOURCE_NAME_MAX_LENGTH.github &&
                      nameDirty)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.github_organization_name?.toLowerCase()}
                  onClick={onResourceNameClick}
                  fullWidth
                  helperText={`Provide a unique name for your GitHub organization. Only letters, numbers and single hyphens allowed. Must not end with hyphen. No. of Characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.github}, Max=${RESOURCE_NAME_MAX_LENGTH.github}`}
                />

                <br />
                <br />
                <InputLabel
                  htmlFor="github_organization_description"
                  className={classes.inputLabelText}
                >
                  Description
                </InputLabel>
                <TextField
                  type="text"
                  id="github_organization_description"
                  name="github_organization_description"
                  onChange={formik.handleChange}
                  value={formik.values.github_organization_description}
                  minRows={4}
                  multiline
                  fullWidth
                  error={
                    formik.values.github_organization_description.length >
                      GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
                  }
                  helperText={`Provide a brief description for your GitHub organization. Number of characters allowed Max = ${GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH}`}
                  placeholder="Example: GitHub Org for developing One-click delivery automation solutions."
                  onClick={onDescriptionClick}
                />
              </Grid>
            </Paper>
            <Paper className={classes.btnContainer}>
              <Grid item xs={12} className={classes.btnItem}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      id="cancel-github"
                      variant="outlined"
                      onClick={handleCreateResource}
                      className={classes.newBtnOutlined}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      id="submit-github"
                      disabled={
                        formik.values.github_organization_name?.length <
                          RESOURCE_NAME_MIN_LENGTH.github ||
                        formik.values.github_organization_name?.length >
                          RESOURCE_NAME_MAX_LENGTH.github ||
                        !formik.values.github_organization_name?.match(
                          '^([a-z0-9]+-)*[a-z0-9]+$',
                        ) ||
                        formik.values.github_organization_description.length >
                          GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH ||
                        apiLoader
                      }
                      variant="contained"
                      className={classes.newBtnContained}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
