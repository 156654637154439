import React from 'react';
import {
  Paper,
  Grid,
  InputLabel,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
} from 'usg-types';
import { ContactUsLink } from '@internal/sg-ui-kit';
import {
  transformResourceName,
  getLocalStorageWithExpiry,
} from 'sg-utils-frontend';
import { useStyles } from '../styles';

export interface CreateArtifactoryResourceFormProps {
  onSubmitCreateArtifactory: (values: any) => Promise<void>;
  artifactoryNameDirty: boolean;
  projectData: any;
  onArtifactoryNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
  packageType: Array<String>;
  userRole: String;
  handleCreateResource: () => void;
}

export const CreateArtifactoryResourceForm = ({
  onSubmitCreateArtifactory,
  artifactoryNameDirty,
  projectData,
  onArtifactoryNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
  packageType,
  userRole,
  handleCreateResource,
}: CreateArtifactoryResourceFormProps) => {
  const language = getLocalStorageWithExpiry('locale');
  const classes = useStyles();
  const rClass = ['local', 'remote', 'virtual'];
  // mtfuji flag status does not matter here
  const resourceName = transformResourceName('artifactory');
  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          artifactory_repo_name: projectData?.key?.toLowerCase(),
          artifactory_description: '',
          package_type: '',
          r_class: '',
        }}
        onSubmit={onSubmitCreateArtifactory}
      >
        {formik => (
          <Form>
            <Paper className={classes.formContainer}>
              <Grid item xs={6} className={classes.selectedResourceItem}>
                <Typography
                  variant="subtitle2"
                  className={classes.selectedResourceText}
                >
                  {resourceName} Repo
                </Typography>
                <p>
                  This will create a repository on Stargate’s Artifactory
                  Self-hosted instance. Unless you have a specific need to use
                  Stargate’s Artifactory Self-hosted instance, please consider
                  using the Artifactory (SaaS) option to create a repository on
                  Stargate’s Artifactory SaaS instance instead.
                </p>
                <InputLabel
                  htmlFor="artifactory_repo_name"
                  className={classes.inputLabelText}
                >
                  {resourceName} Repo Name*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="artifactory_repo_name"
                  name="artifactory_repo_name"
                  data-testid="artifactory_repo_name"
                  required
                  error={
                    (formik.values.artifactory_repo_name?.length <
                      RESOURCE_NAME_MIN_LENGTH.artifactory &&
                      artifactoryNameDirty) ||
                    (formik.values.artifactory_repo_name?.length >
                      RESOURCE_NAME_MAX_LENGTH.artifactory &&
                      artifactoryNameDirty) ||
                    (formik.values.artifactory_repo_name?.length > 0 &&
                      !formik.values.artifactory_repo_name?.match(
                        SPECIAL_RESOURCE_NAME_REGEX.artifactory,
                      ))
                  }
                  onChange={formik.handleChange}
                  value={formik.values.artifactory_repo_name}
                  onClick={onArtifactoryNameClick}
                  fullWidth
                  helperText={`Provide a unique name for your ${resourceName} repo. Names should consist only of letters, numbers and single hyphens, must not start with a number, and must not end with a hyphen or 'cache'. Number of characters allowed: Minimum=${RESOURCE_NAME_MIN_LENGTH.artifactory}, Maximum=${RESOURCE_NAME_MAX_LENGTH.artifactory}`}
                />
                <br />
                <br />

                <InputLabel
                  htmlFor="artifactory_description"
                  className={classes.inputLabelText}
                >
                  Description
                </InputLabel>
                <TextField
                  type="text"
                  id="artifactory_description"
                  name="artifactory_description"
                  onChange={formik.handleChange}
                  value={formik.values.artifactory_description}
                  minRows={4}
                  multiline
                  fullWidth
                  error={
                    formik.values.artifactory_description.length >
                      RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
                  }
                  helperText={`Provide a brief description for your ${resourceName} repo. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH}`}
                  placeholder="Example : Repository for storing the artifacts for development within the One-click delivery automation solutions project."
                  onClick={onDescriptionClick}
                />
                <br />
                <br />
                <InputLabel
                  style={{ marginBottom: '8px' }}
                  className={classes.inputLabelText}
                >
                  Config*
                </InputLabel>
                {userRole === 'user' && (
                  <>
                    <Typography className={classes.artifactoryOwnerAlertText}>
                      {`Virtual and Remote repositories can not be created
                    automatically.`}
                      <br />
                      {`Please `}
                      <ContactUsLink
                        text="contact us to raise a ticket"
                        language={language}
                        target="_blank"
                      />
                      {` to our Service Desk for these repositories instead.`}
                    </Typography>
                    <br />
                  </>
                )}
                <div style={{ paddingLeft: '24px' }}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="package_type">Package Type</InputLabel>
                    <Select
                      id="package_type"
                      name="package_type"
                      data-testid="package_type_select"
                      value={formik.values.package_type}
                      input={<OutlinedInput label="Tag" />}
                      onChange={formik.handleChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {packageType.map((value: any) => {
                        return (
                          <MenuItem
                            value={value}
                            style={{ textTransform: 'capitalize' }}
                            key={value}
                          >
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br />
                  <br />

                  <FormControl fullWidth>
                    <InputLabel htmlFor="r_class">R Class</InputLabel>
                    <Select
                      id="r_class"
                      name="r_class"
                      data-testid="r_class"
                      value={formik.values.r_class}
                      input={<OutlinedInput label="Tag" />}
                      onChange={e => {
                        e.preventDefault();
                        formik.handleChange(e);
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {rClass.map((value: any) => {
                        return (
                          <MenuItem
                            value={value}
                            disabled={value !== 'local' && userRole === 'user'}
                            style={{ textTransform: 'capitalize' }}
                            key={value}
                          >
                            {value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Paper>
            <Paper className={classes.btnContainer}>
              <Grid item xs={12} className={classes.btnItem}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      id="cancel-artifactory"
                      variant="outlined"
                      onClick={handleCreateResource}
                      className={classes.newBtnOutlined}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      id="submit-artifactory"
                      data-testid="submit-artifactory"
                      disabled={
                        formik.values.artifactory_repo_name?.length <
                          RESOURCE_NAME_MIN_LENGTH.artifactory ||
                        formik.values.artifactory_repo_name?.length >
                          RESOURCE_NAME_MAX_LENGTH.artifactory ||
                        (formik.values.artifactory_repo_name?.length > 0 &&
                          !formik.values.artifactory_repo_name.match(
                            SPECIAL_RESOURCE_NAME_REGEX.artifactory,
                          )) ||
                        apiLoader ||
                        formik.values.package_type === '' ||
                        formik.values.r_class === '' ||
                        formik.values.artifactory_description.length >
                          RESOURCE_DESCRIPTION_MAX_LENGTH
                      }
                      variant="contained"
                      className={classes.newBtnContained}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
