import React, { useState } from 'react';
import { Button, FormHelperText, TextField } from '@material-ui/core';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
  DEVEX_TOOL_TOUCAN,
} from 'usg-types';
import { Link } from 'react-router-dom';
import { GenricResourcePropsType } from './Types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import SyncIcon from '@material-ui/icons/Sync';
import { BinaryFeatureFlagged } from '@internal/sg-ui-kit';
import { projectApiRef } from '../../../api';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { usePermissions } from '@internal/plugin-projects';
import { getTechDocsLink } from 'sg-utils-frontend';

type specificPropsType = {
  dataData: any;
  nameDirty: boolean;
  setNameDirty: React.Dispatch<React.SetStateAction<boolean>>;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  projectId: string;
};

const GithubEmuViewEdit = (
  props: React.PropsWithChildren<GenricResourcePropsType<specificPropsType>>,
) => {
  const authApi = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const { isAdmin, isProjectOwner, error: permissionsError } = usePermissions();

  const { eventType, resourceData, formik, specificProps, classes } = props;
  const {
    nameDirty,
    setNameDirty,
    descriptionDirty,
    onDescriptionClick,
    projectId,
  } = specificProps;

  const canGetWebhookSecret = isAdmin || isProjectOwner(Number(projectId));

  const [webhookSecretButtonState, setWebhookSecretButtonState] = useState({
    label: 'Webhook Secret',
    icon: FileCopyIcon,
    disabled: false,
  });
  const onWebhookSecretClick = async () => {
    setWebhookSecretButtonState({
      label: 'Generating...',
      icon: SyncIcon,
      disabled: true,
    });

    const idToken = await authApi.getIdToken();
    const orgName = resourceData.url.toLowerCase().replaceAll('https://', '');

    try {
      const data: any = await projectApi.getGitHubOrgWebhookSecret(
        idToken,
        orgName,
      );
      try {
        await navigator.clipboard.writeText(data.secret);
      } catch (error) {
        // Ignore errors when document is no longer focused
      }
      setWebhookSecretButtonState({
        label: 'Copied',
        icon: CheckIcon,
        disabled: true,
      });
    } catch (error) {
      setWebhookSecretButtonState({
        label: 'Failed',
        icon: ErrorIcon,
        disabled: true,
      });

      const errorMessage = `Failed to get GitHub Organization Webhook Secret. ${error} `;
      errorApi.post(new Error(errorMessage));
    }

    setTimeout(() => {
      setWebhookSecretButtonState({
        label: 'Webhook Secret',
        icon: FileCopyIcon,
        disabled: false,
      });
    }, 2000);
  };

  if (permissionsError) {
    errorApi.post(permissionsError);
  }

  const viewCase = (
    <>
      <h4 className={classes.labelHeader}>GitHub Organization Key</h4>
      <p data-testid="resource-key-value" className={classes.viewTag}>
        {resourceData?.key}
      </p>
      <h4 className={classes.labelHeader}>GitHub Organization Name</h4>
      <p data-testid="resource-name-value" className={classes.viewTag}>
        {resourceData?.name}
      </p>
      {resourceData?.description ? (
        <>
          <h4 className={classes.labelHeader}>Description</h4>
          <p id="resource-name-description" className={classes.viewTag}>
            {resourceData?.description ? resourceData.description : ''}
          </p>
        </>
      ) : null}{' '}
      <BinaryFeatureFlagged withFlag={DEVEX_TOOL_TOUCAN}>
        {canGetWebhookSecret && resourceData?.url ? (
          <>
            <h4 className={classes.labelHeader}>GitHub to Jira Integration</h4>
            <p data-testid="resource-name-value" className={classes.viewTag}>
              To connect any repository in this GitHub Organization to Jira,
              please follow{' '}
              <Link
                to={getTechDocsLink('jira-github-emu-integration')}
                style={{
                  textDecorationLine: 'underline',
                  color: '#307FFD',
                }}
              >
                this guideline
              </Link>{' '}
              using the secret below:
            </p>
            <div>
              <Button
                variant="outlined"
                onClick={() => onWebhookSecretClick()}
                disabled={webhookSecretButtonState.disabled}
              >
                {webhookSecretButtonState.label}{' '}
                <webhookSecretButtonState.icon
                  style={{ marginLeft: '8px' }}
                  fontSize="small"
                />
              </Button>
              <FormHelperText>
                Click to generate and copy webhook secret.
              </FormHelperText>
            </div>
          </>
        ) : null}{' '}
      </BinaryFeatureFlagged>
    </>
  );

  const editCase = (
    <>
      <h4 className={classes.labelHeader}>GitHub Organization Key</h4>
      <p className={classes.viewTag}>{resourceData?.key}</p>
      <h4 className={classes.labelHeader}>GitHub Organization Name*</h4>
      <TextField
        style={{ paddingBottom: '0%' }}
        type="text"
        id="resourceName"
        name="resourceName"
        required
        error={
          (formik?.values?.resourceName?.length <
            RESOURCE_NAME_MIN_LENGTH.github &&
            nameDirty === true) ||
          !formik?.values?.resourceName?.match(
            SPECIAL_RESOURCE_NAME_REGEX.github,
          ) ||
          (formik?.values?.resourceName?.length >
            RESOURCE_NAME_MAX_LENGTH.github &&
            nameDirty === true)
        }
        onChange={formik.handleChange}
        value={formik?.values?.resourceName}
        onClick={() => setNameDirty(true)}
        fullWidth
        helperText={`Provide a unique name for your GitHub organization. Only letters, numbers and single hyphens allowed. Must not end with hyphen. No. of Characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.github}, Max=${RESOURCE_NAME_MAX_LENGTH.github}`}
      />
      <h4 className={classes.labelHeader}>Description</h4>
      <TextField
        id="resource-description"
        name="resourceDescription"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.resourceDescription}
        rows={3}
        multiline
        fullWidth
        error={
          formik?.values?.resourceDescription?.length >
            GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
        }
        helperText={`Provide a brief description for your GitHub organization to explain what it is for. The description shows up on your organization's profile page. Number of characters allowed Max = ${GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH}`}
        placeholder="Example: GitHub organization for developing vehicle software component."
        onClick={onDescriptionClick}
      />
    </>
  );

  switch (eventType) {
    case 'edit':
      return editCase;
    default:
      return viewCase;
  }
};

export default GithubEmuViewEdit;
