export const ARTIFACTORY_SAAS_NAME = 'SaaS';

export const ARTIFACTORY_SAAS_PROD_HOST = 'artifactory.stargate.toyota';
export const ARTIFACTORY_SAAS_NON_PROD_HOST =
  'artifactory.test.stargate.toyota';
export const ARTIFACTORY_SELF_HOSTED_NAME = 'SelfHosted';

export const ARTIFACTORY_PROD_HOST = 'artifactory-ha.tmc-stargate.com';
export const ARTIFACTORY_NON_PROD_HOST = 'artifactory-ha.stg.tmc-stargate.com';
export const GITHUB_HOST = 'github.com';

export const HIDDEN_ENVIRONMENT_CONFIG_KEYS = [
  'aad_readonly_group_id',
  'aad_developer_group_id',
  'aad_user_email',
];

export const OPTIONAL_ENVIRONMENT_CONFIG_KEYS = [
  'aad_user_email',
  'user_labels',
  'service_account_admins',
];

export const DEFAULT_CONFIG_ENVIRONMENTS = ['prod', 'dev', 'stage'];
