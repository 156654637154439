import React, { useEffect, useMemo, useRef } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  TablePagination,
  Tooltip,
  FormHelperText,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { Table, TableColumn } from '@backstage/core-components';
import { AlertNotification, EmptyDataMessage } from '@internal/sg-ui-kit';
import { useParams } from 'react-router';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { useAsyncFn } from 'react-use';
import GetAppIcon from '@material-ui/icons/GetApp';
import DatePicker from 'react-multi-date-picker';
import { CSVLink } from 'react-csv';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
  REVIEWABLE_SAAS_LICENSE_USAGE,
} from 'usg-types';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { licenseUsageApiRef } from '@internal/backstage-plugin-chargeback';

import { useStyles } from './styles';

interface CloudLicenseUsageProps {
  projId?: string;
}

export const CloudLicenseUsage = ({ projId }: CloudLicenseUsageProps) => {
  const classes = useStyles();
  const { projectId = projId } = useParams();
  const authref = useApi(microsoftAuthApiRef);
  const licenseUsageApi = useApi(licenseUsageApiRef);
  const errorApi = useApi(errorApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const [showReviewable, setShowReviewable] = React.useState(false);
  const [cloudUsageCSVData, setCloudUsageCSVData] = React.useState([] as any);
  const [downloadInProgress, setDownloadInProgress] = React.useState(true);
  const [isNoRecords, setIsNoRecords] = React.useState(false);
  const csvRef = useRef<any>();

  const [tableState, setTableState] = React.useState({
    page: 1,
    pageSize: DEFAULT_TABLE_PAGE_SIZE_OPTIONS[0],
  });

  React.useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('usageType', 'license');
    window.history.pushState(null, '', url.toString());
  }, []);

  // Commenting out this code in case in the future we may need to check ADMIN role
  // again for new features.
  // const [{ value: roles }, fetchRoles] = useAsyncFn(async () => {
  //   const token = await authref.getIdToken();
  //   const paramsForUserDetails = {
  //     idToken: token,
  //   };
  //   const queryParams = {
  //     include: 'owned_projects',
  //   };
  //   const res: any = await projectApi.getUserDetails(
  //     paramsForUserDetails,
  //     queryParams,
  //   );
  //   if (res?.response?.status === 200) {
  //     let roleArray = getRoles(res?.response?.data?.roles);
  //     return roleArray;
  //   }
  //   return [];
  // }, []);

  const startTimeFormat = (date: any) => {
    return new Date(
      Date.UTC(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0,
      ),
    );
  };
  const endTimeFormat = (date: any) => {
    return new Date(
      Date.UTC(
        new Date(date).getFullYear(),
        new Date(date).getMonth() + 1,
        new Date(date).getDate() - 1,
        23,
        59,
        59,
        0,
      ),
    );
  };
  const defaultDate = new Date();
  const defaultEndDate = new Date(
    Date.UTC(
      defaultDate.getFullYear(),
      defaultDate.getMonth(),
      defaultDate.getDate(),
      23,
      59,
      59,
      0,
    ),
  );
  defaultDate.setMonth(defaultDate.getMonth() - 1, 1);
  const [values, setValues] = React.useState([defaultDate, new Date()]);
  const [dates, setDates] = React.useState({
    start_time: startTimeFormat(defaultDate),
    end_time: defaultEndDate,
  });

  const headers = useMemo(() => {
    const headerConfig = [
      { label: 'User Email', key: 'email' },
      { label: 'GithubEMU', key: 'github_active' },
      { label: 'Github', key: 'ghe_active' },
      { label: 'Jira', key: 'jira_active' },
      { label: 'Confluence', key: 'confluence_active' },
      { label: 'Jama', key: 'jama_active' },
      { label: 'Vault', key: 'vault_active' },
      { label: 'GHAS', key: 'ghas_active' },
    ];

    if (showReviewable) {
      headerConfig.push({ label: 'Reviewable', key: 'reviewable_active' });
    }

    return headerConfig;
  }, [showReviewable]);

  const emailWidthPercent = 40;
  const devtoolWidth = Math.floor(
    (100 - emailWidthPercent) / (headers.length - 1),
  );

  const feedColumns: Array<TableColumn<any>> = useMemo(() => {
    const columnsConfig: Array<TableColumn<any>> = [
      {
        field: 'email',
        title: 'User Email',
        width: `${emailWidthPercent}%`,
        sorting: false,
        render(data) {
          const hrefValue = `/projects/${projectId}/users/${data?.email}`;
          return <Link href={hrefValue}>{data?.email}</Link>;
        },
      },
      {
        field: 'github_active',
        title: 'GithubEMU',
        width: `${devtoolWidth}%`,
        sorting: false,
        render: ({ github_active }) => {
          if (github_active) {
            return <CheckCircleIcon className={classes.checkColor} />;
          }
          return '-';
        },
      },
      {
        field: 'ghe_active',
        title: 'Github',
        sorting: false,
        width: `${devtoolWidth}%`,
        render: ({ ghe_active }) => {
          if (ghe_active) {
            return <CheckCircleIcon className={classes.checkColor} />;
          }
          return '-';
        },
      },
      {
        field: 'jira_active',
        title: 'JIRA',
        sorting: false,
        width: `${devtoolWidth}%`,
        render: ({ jira_active }) => {
          if (jira_active) {
            return <CheckCircleIcon className={classes.checkColor} />;
          }
          return '-';
        },
      },
      {
        field: 'confluence_active',
        title: 'Confluence',
        sorting: false,
        width: `${devtoolWidth}%`,
        render: ({ confluence_active }) => {
          if (confluence_active) {
            return <CheckCircleIcon className={classes.checkColor} />;
          }
          return '-';
        },
      },
      {
        field: 'jama_active',
        title: 'Jama',
        sorting: false,
        width: `${devtoolWidth}%`,
        render: ({ jama_active }) => {
          if (jama_active) {
            return <CheckCircleIcon className={classes.checkColor} />;
          }
          return '-';
        },
      },
      {
        field: 'vault_active',
        title: 'Vault',
        sorting: false,
        width: `${devtoolWidth}%`,
        render: ({ vault_active }) => {
          if (vault_active) {
            return <CheckCircleIcon className={classes.checkColor} />;
          }
          return '-';
        },
      },
      {
        field: 'ghas_active',
        title: 'GHAS',
        sorting: false,
        width: `${devtoolWidth}%`,
        render: ({ ghas_active }) => {
          if (ghas_active) {
            return <CheckCircleIcon className={classes.checkColor} />;
          }
          return '-';
        },
      },
    ];

    if (showReviewable) {
      columnsConfig.push({
        field: 'reviewable_active',
        title: 'Reviewable',
        sorting: false,
        width: `${devtoolWidth}%`,
        render: ({ reviewable_active }) => {
          if (reviewable_active) {
            return <CheckCircleIcon className={classes.checkColor} />;
          }
          return '-';
        },
      } as TableColumn<any>);
    }

    return columnsConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReviewable]);

  const [CloudLicenseUsageData, setCloudLicenseUsageData] = React.useState(
    {} as any,
  );

  const [tableData, setTableData] = React.useState([] as any);

  const [{ loading }, fetchCloudData] = useAsyncFn(async () => {
    setIsNoRecords(false);
    const token = await authref.getIdToken();
    const params = {
      start_time: dates.start_time.toISOString(),
      end_time: dates.end_time.toISOString(),
      page: tableState.page,
      size: tableState.pageSize,
      // need to revisit during actual API integration
      filter: `jira,confluence,github,ghe,jama,vault,ghas${
        showReviewable ? ',reviewable' : ''
      }`,
    } as any;
    const idToken = {
      idToken: token,
    };
    const data: any = await licenseUsageApi.getLicenseUsage(
      projectId,
      idToken,
      params,
    );

    if (data?.response?.status === 200) {
      setTableData(data?.response?.data?.users);
      setCloudLicenseUsageData(data?.response?.data);
    } else {
      setTableData([]);
      setCloudLicenseUsageData([]);
    }
    const code = data?.response?.data?.status;
    if (code === 401 || code === 404 || code === 403) {
      const error = data?.response?.data?.message;
      errorApi.post(new Error(`${error}`));
    }
    if (data?.response?.status === 500 || data?.response?.status === 404) {
      const error = data?.response?.statusText;
      errorApi.post(new Error(`${error}`));
    }
    setDownloadInProgress(false);
    return data;
  }, [tableState, dates, showReviewable]);

  React.useEffect(() => {
    (async () => {
      const response = await featureFlagsApi.getBinaryFlag(
        REVIEWABLE_SAAS_LICENSE_USAGE,
      );
      setShowReviewable(response.data);
    })();
  }, [featureFlagsApi]);

  React.useEffect(
    () => {
      setDownloadInProgress(true);
      fetchCloudData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableState, dates],
  );

  // increment row
  const handleRowChange = (e: any) => {
    const pageSize = e.target.value;
    const page = 1;
    setTableState({ ...tableState, pageSize, page });
  };

  // increment page
  const handlePageChange = (_: any, newPage: number) => {
    const page = newPage + 1;
    setTableState({ ...tableState, page });
  };

  const helper_function = async (count: any) => {
    const data = (await authref.getIdToken().then(async (token: any) => {
      const params = {
        page: count,
        size: 1000,
        start_time: dates.start_time.toISOString(),
        end_time: dates.end_time.toISOString(),
        // need to revisit during actual API integration
        filter: `jira,confluence,github,ghe,jama,vault,ghas${
          showReviewable ? ',reviewable' : ''
        }`,
      } as any;
      const idToken = {
        idToken: token,
      };

      return await licenseUsageApi.getLicenseUsage(projectId, idToken, params);
    })) as any;
    const code = data?.response?.data?.status;
    if (code === 401 || code === 404 || code === 403) {
      const error = data?.response?.data?.message;
      errorApi.post(new Error(`${error}`));
    }
    const statusCode = data?.response?.status;
    if (statusCode !== 200) {
      const error = data?.response?.statusText;
      errorApi.post(new Error(`${error}`));
    }
    return data;
  };
  const handleDownloadCSVReport = async () => {
    setIsNoRecords(false);
    setDownloadInProgress(true);
    if (tableData?.length === 0) {
      setIsNoRecords(true);
      setDownloadInProgress(false);
      return;
    }
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(CloudLicenseUsageData?.total_user_count / 1000);
      i++
    ) {
      pageNumbers.push(i);
    }
    const promises: any[] = [];
    Object.keys(pageNumbers).forEach((count: any) => {
      promises.push(helper_function(parseInt(count, 10) + 1));
    });
    const totalData = await Promise.all(promises).then(result => {
      return result;
    });
    const couldCSVData1 = totalData
      .map(i => Object.values(i?.response?.data?.users).flat())
      .flat();
    setCloudUsageCSVData(couldCSVData1);
    setDownloadInProgress(false);
  };

  useEffect(() => {
    if (cloudUsageCSVData.length > 0) {
      csvRef.current.link.click();
    }
  }, [cloudUsageCSVData]);

  const getEndTime = (startDate: any, endDate: any, endTime: any) => {
    const currentDate = new Date();
    if (new Date(endTime).toString() === 'Invalid Date') {
      if (new Date(startDate).getMonth() === currentDate.getMonth()) {
        return defaultEndDate;
      }
      return endTimeFormat(startDate);
    }
    if (new Date(endDate).getMonth() === currentDate.getMonth()) {
      return defaultEndDate;
    }
    return endTime;
  };

  return (
    <div>
      <AlertNotification
        severity="warning"
        alertMessage="There is no data to download"
        condition={isNoRecords}
      />
      <Grid container spacing={2} className={classes.resourceFilterSection}>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <Typography
              variant="button"
              component="div"
              style={{ marginBottom: '8px', textTransform: 'none' }}
              id="sort-label"
            >
              Month
            </Typography>
            <div className={classes.monthDate}>
              <DatePicker
                id="cu-date-picker"
                value={values as any}
                onlyMonthPicker
                minDate={new Date('01-09-2022')}
                render={<input type="text" readOnly />}
                maxDate={new Date()}
                onChange={(dateObject: any) => {
                  const startDate = dateObject[0];
                  const endDate = dateObject[1];
                  setValues(dateObject);
                  const start_time = startTimeFormat(startDate);
                  const endTime = endTimeFormat(endDate);
                  const end_time = getEndTime(startDate, endDate, endTime);
                  setDates({ ...dates, start_time, end_time });
                }}
                range
                rangeHover
              />
            </div>
            <FormHelperText>Select month or month range.</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={1} className={classes.downloadIcon}>
          {downloadInProgress ? (
            <CircularProgress
              className={classes.downloadProgress}
              size="24px"
            />
          ) : (
            <Tooltip
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
              arrow
              placement="left"
              title="Download Report CSV"
            >
              <GetAppIcon
                id="download-csv"
                color="primary"
                onClick={() => handleDownloadCSVReport()}
              />
            </Tooltip>
          )}
          <CSVLink
            data={cloudUsageCSVData}
            headers={headers}
            filename="license-usage.csv"
            asyncOnClick
            ref={csvRef}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.lblNote}>
            <strong>Note:</strong> GitHub License count includes both GitHub
            Enterprise and GitHub EMU, it is considered a single GitHub license.
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography className={classes.totalText}>
              <span className={classes.totalTitle}>Total Licenses: </span>
              &nbsp;&nbsp;
              <span className={classes.totalTitle}>Github: </span> &nbsp;&nbsp;
              <span id="github-au">
                {CloudLicenseUsageData?.total_github_combined_active_users || 0}
              </span>
              &nbsp;&nbsp;
              <span className={classes.totalTitle}>Jira: </span>{' '}
              <span id="jira-au">
                {CloudLicenseUsageData?.total_jira_active_users || 0}
              </span>
              &nbsp;&nbsp;
              <span className={classes.totalTitle}>Confluence: </span> {'  '}
              <span id="confluence-au">
                {CloudLicenseUsageData?.total_confluence_active_users || 0}
              </span>
              &nbsp;&nbsp;
              <span className={classes.totalTitle}>Jama: </span> {'  '}
              <span id="jama-au">
                {CloudLicenseUsageData?.total_jama_active_users || 0}
              </span>
              &nbsp;&nbsp;
              <span className={classes.totalTitle}>Vault: </span> {'  '}
              <span id="vault-au">
                {CloudLicenseUsageData?.total_vault_active_users || 0}
              </span>
              &nbsp;&nbsp;
              <span className={classes.totalTitle}>GHAS: </span> {'  '}
              <span id="ghas-au">
                {CloudLicenseUsageData?.total_ghas_active_users || 0}
              </span>
              &nbsp;&nbsp;
              {showReviewable && (
                <>
                  <span className={classes.totalTitle}>Reviewable: </span>{' '}
                  {'  '}
                  <span id="reviewable-au">
                    {CloudLicenseUsageData?.total_reviewable_active_users || 0}
                  </span>
                  &nbsp;&nbsp;
                </>
              )}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Table
        isLoading={loading}
        columns={feedColumns}
        options={{
          paging: true,
          search: false,
          sorting: true,
          actionsColumnIndex: -1,
          padding: 'dense',
          pageSizeOptions: DEFAULT_TABLE_PAGE_SIZE_OPTIONS,
          emptyRowsWhenPaging: false,
          showFirstLastPageButtons: false,
          actionsCellStyle: { padding: '5px 10px' },
          pageSize:
            DEFAULT_TABLE_PAGE_SIZE_OPTIONS[
              DEFAULT_TABLE_PAGE_SIZE_OPTIONS.length - 1
            ],
          headerStyle: {
            fontWeight: 700,
            fontSize: '12px',
            textTransform: 'uppercase',
            color: '#0E1319B2',
            letterSpacing: '0.01em',
          },
        }}
        data={tableData}
        title="License Usage"
        localization={{
          body: {
            emptyDataSourceMessage: <EmptyDataMessage />,
          },
        }}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              rowsPerPageOptions={DEFAULT_TABLE_PAGE_SIZE_OPTIONS}
              count={CloudLicenseUsageData?.total_user_count || 0}
              page={tableState.page - 1}
              onPageChange={handlePageChange}
              rowsPerPage={tableState.pageSize}
              onRowsPerPageChange={handleRowChange}
            />
          ),
        }}
      />
    </div>
  );
};
