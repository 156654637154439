import React, { useEffect, useState } from 'react';
import { Typography, Grid, Radio, Paper } from '@material-ui/core';
import { PageLayout } from '@internal/sg-ui-kit';
import { rootRouteRef } from '../../routes';
import { useRouteRef } from '@backstage/core-plugin-api';
import { useAsyncFn } from 'react-use';
import { Progress } from '@backstage/core-components';
import { projectApiRef } from '../../api';
import {
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { ProjectOwners } from '../../pages/ProjectDetails/ProjectOwners';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { Wizard } from '@internal/plugin-mtfuji';
import { CustomDialogMapper } from './custom-dialog-mapper';
import { getTechDocsLink, transformResourceName } from 'sg-utils-frontend';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  ARTIFACTORY_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  VAULT_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  RESOURCE_CREATOR,
  RESOURCE_ENABLED_DEVTOOLS,
  STOP_GITHUB_AND_SELF_HOSTED_ATLASSIAN,
} from 'usg-types';
import { CreateGithubResourceForm } from './create-resource-forms/CreateGithubResourceForm';
import { CreateGithubEMUResourceForm } from './create-resource-forms/CreateGithubEMUResourceForm';
import { CreateArtifactoryResourceForm } from './create-resource-forms/CreateArtifactoryResourceForm';
import { CreateConfluenceResourceForm } from './create-resource-forms/CreateConfluenceResourceForm';
import { CreateVaultResourceForm } from './create-resource-forms/CreateVaultResourceForm';
import { CreateJiraResourceForm } from './create-resource-forms/CreateJiraResourceForm';
import { CreateJamaResourceForm } from './create-resource-forms/CreateJamaResourceForm';
import { CreateArtifactorySaaSResourceForm } from './create-resource-forms/CreateArtifactorySaaSResourceForm';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { usePermissions } from '@internal/plugin-projects';
import { useStyles } from './styles';

interface CreateResourceComponentProps {
  projectId: string;
}

export const CreateResourceComponent = ({
  projectId,
}: CreateResourceComponentProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const rootLink = useRouteRef(rootRouteRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);

  const [resourcesList, setResourcesList] = useState<
    {
      id: number;
      name: string;
      description: string;
      enable_users_environment: boolean;
      default_role_id: number;
      roles: {
        id: number;
        name: string;
        display_name: string;
        description: string;
      }[];
      environments: {
        id: number;
        key: string;
        name: string;
        description: string;
      }[];
    }[]
  >([]);
  const [isVaultNewUIEnabled, setIsVaultNewUIEnabled] = useState(false);
  const [selectedResource, setSelectedResource] = useState('');
  const [nameDirty, setNameDirty] = useState(false);
  const [descriptionDirty, setDescriptionDirty] = useState(false);
  const [keyDirty, setKeyDirty] = useState(false);
  const [vaultNameDirty, setVaultNameDirty] = useState(false);
  const [artifactoryNameDirty, setArtifactoryNameDirty] = useState(false);
  const [rClass, setRClass] = useState('');
  const [enviromentsData, setEnviromentsData] = useState([] as any);
  const [open, setOpen] = useState(false);
  const [idToken, setIdToken] = useState<any>();
  const [apiLoader, setApiLoader] = useState(false);
  const [newResourceId, setNewResourceId] = useState('');
  const [isGithubAtlassianStopped, setIsGithubAtlassianStopped] =
    useState(false);

  const [enabledDevTools, setEnabledDevTools] = useState(
    RESOURCE_ENABLED_DEVTOOLS,
  );
  const {
    isAdmin,
    isProjectOwner,
    isLoading: rolesCheckLoading,
    isResourceCreator,
    error: permissionsError,
  } = usePermissions();

  const packageType = [
    'alpine',
    'cargo',
    'composer',
    'bower',
    'chef',
    'cocoapods',
    'conan',
    'cran',
    'debian',
    'docker',
    'helm',
    'gems',
    'gitlfs',
    'go',
    'gradle',
    'ivy',
    'maven',
    'npm',
    'nuget',
    'opkg',
    'pub',
    'puppet',
    'pypi',
    'rpm',
    'sbt',
    'swift',
    'terraform',
    'vagrant',
    'yum',
    'generic',
  ];

  const projectTemplates = [
    'kanban',
    'scrum',
    'software-development',
    'project-management',
    'task-management',
    'process-management',
  ];
  const backToTarget = projectId ? `/projects/${projectId}` : rootLink();
  const backToLink = React.useMemo(
    () => ({
      to: backToTarget,
      label: projectId ? 'Back to Project' : 'Back to Projects',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [backToTarget],
  );

  const getEnabledDevTools = (isAdminOrOwner: boolean) => {
    if (isAdminOrOwner) {
      setEnabledDevTools(prev => [...prev, ARTIFACTORY_SAAS_DEV_TOOL_ID]);
    }
  };

  const [
    { value: projectData = [] as any, loading, error: fetchProjectDataError },
    fetchProjectData,
  ] = useAsyncFn(
    async token => {
      let data;
      try {
        const params = { idToken: token };
        data = await projectApi.getProjectByID(projectId, token, {
          manipulators: ['user_groups'],
        });

        const queryParams = {
          include: 'all',
        };

        const res: any = await projectApi.getUserDetails(params, queryParams);
        const userData = res?.response?.data;
        const foundProject: any = userData?.projects.find(
          (p: any) => p.id.toString() === projectId,
        );

        getEnabledDevTools(isAdmin || isProjectOwner(Number(projectId)));

        setSelectedResource(
          foundProject?.development_tools?.length > 0
            ? foundProject?.development_tools[0].id.toString()
            : MTFUJI_DEV_TOOL_ID.toString(),
        );

        const resourceCreators = foundProject?.development_tools?.some(
          (tool: any) =>
            tool?.dev_tool_roles?.some(
              (element: string) => element === RESOURCE_CREATOR,
            ),
        );
        if (
          !isAdmin &&
          !isProjectOwner(Number(projectId)) &&
          !resourceCreators
        ) {
          navigate(`/projects/${projectId}`);
        }
      } catch (err) {
        errorApi.post(new Error(`${err?.message}`));
        navigate('/projects');
      }
      return data;
    },
    [projectId, rolesCheckLoading],
  );

  const [
    { loading: loadingDevelopmentToolsData = false as boolean },
    fetchDevelopmentToolsData,
  ] = useAsyncFn(async token => {
    try {
      const isGithubAtlassianStoppedFlag: any =
        await featureFlagsApi.getBinaryFlag(
          STOP_GITHUB_AND_SELF_HOSTED_ATLASSIAN,
        );
      setIsGithubAtlassianStopped(isGithubAtlassianStoppedFlag?.data);
      const devToolData: any = await projectApi.getDevelopmentToolsData({
        idToken: token,
      });
      if (isGithubAtlassianStoppedFlag?.data) {
        setResourcesList(
          devToolData?.response?.data.development_tools.filter(
            (dev_tool: any) =>
              dev_tool.id !== GITHUB_DEV_TOOL_ID &&
              dev_tool.id !== ARTIFACTORY_DEV_TOOL_ID,
          ),
        );
      } else {
        setResourcesList(devToolData?.response?.data.development_tools);
      }
      setEnviromentsData(
        devToolData?.response?.data.development_tools?.filter(
          (t: any) => t.id === 105,
        )[0]?.environments,
      );
    } catch (errorMsg) {
      errorApi.post(new Error(`${errorMsg}`));
    }
  }, []);

  useEffect(() => {
    if (!rolesCheckLoading) {
      (async () => {
        const token = await authref.getIdToken();
        setIdToken(token);
        await fetchProjectData(token);
        // getting Development tools data
        await fetchDevelopmentToolsData(token);
      })();
    }
  }, [rolesCheckLoading, fetchProjectData, fetchDevelopmentToolsData, authref]);

  if (loading || loadingDevelopmentToolsData || rolesCheckLoading) {
    return <Progress />;
  }

  if (fetchProjectDataError) {
    errorApi.post(new Error(`${fetchProjectDataError}`));
  }

  if (permissionsError) {
    errorApi.post(permissionsError);
  }

  const handleChange = (e: any) => {
    setVaultNameDirty(false);
    setArtifactoryNameDirty(false);
    setNameDirty(false);
    setKeyDirty(false);
    setDescriptionDirty(false);
    setSelectedResource('');
    setTimeout(() => {
      setSelectedResource(e.target.name);
    }, 100);
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleCreateResource = () => {
    navigate(`/projects/${projectId}`);
  };

  const handleDialogClose: (
    modelCloseType: 'assign-user-groups' | 'close',
  ) => void = modelCloseType => {
    switch (modelCloseType) {
      case 'assign-user-groups':
        setOpen(false);
        navigate(`/projects/${projectId}/manageresource/${newResourceId}`);
        break;
      default:
        setOpen(false);
        navigate(`/projects/${projectId}`);
        break;
    }
  };

  const bodyMapper = (data: any) => {
    const resource = parseInt(selectedResource, 10);
    switch (resource) {
      case GITHUB_DEV_TOOL_ID:
        return {
          idToken,
          key: data.github_organization_name,
          name: data.github_organization_name,
          description: data.github_organization_description,
          dev_tool_id: resource,
        };
      case GITHUBEMU_DEV_TOOL_ID:
        return {
          idToken,
          key: data.githubemu_organization_name,
          name: data.githubemu_organization_name,
          description: data.githubemu_organization_description,
          dev_tool_id: resource,
        };
      case JIRA_DEV_TOOL_ID:
        return {
          idToken,
          key: data.jira_project_key.toUpperCase(),
          name: data.jira_project_name.trim(),
          description: data.jira_project_description,
          dev_tool_id: resource,
          config: {
            project_template: data.project_template,
          },
        };
      case JAMA_DEV_TOOL_ID:
        return {
          idToken,
          key: data.jama_resource_key,
          name: data.jama_resource_name.trim(),
          description: data.jama_resource_description,
          dev_tool_id: resource,
        };
      case CONFLUENCE_DEV_TOOL_ID:
        return {
          idToken,
          key: data.confluence_key,
          name: data.confluence_name.trim(),
          description: data.confluence_description,
          dev_tool_id: resource,
        };

      default:
        return {};
    }
  };

  const onResourceSubmit = async (values: any) => {
    setApiLoader(true);
    const body: any = bodyMapper(values);
    let fine = true;
    try {
      const rsp: any = await projectApi.createNewProjectResource(
        projectId,
        body,
      );

      const code = rsp?.response?.status;
      if (code === 401 || code === 404 || code === 400) {
        const error = rsp?.response?.data?.message;
        errorApi.post(new Error(`${error}`));
        fine = false;
      }

      setNewResourceId(rsp.response?.data?.id);
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
      fine = false;
    }
    setApiLoader(false);
    if (fine) {
      handleDialogOpen();
    }
  };

  const onSubmitCreateVault = async (values: any) => {
    let fine = true;
    window.scrollTo(0, 0);
    setApiLoader(true);
    if (isVaultNewUIEnabled) {
      const project_id = projectData.id.toString();
      const paramsForCreateNewProjectResource: any = {
        idToken,
        key: values.vault_namespace.toLowerCase(),
        name: values.vault_namespace,
        dev_tool_id: Number(selectedResource),
        environments: values.vaultEnvironments,
      };
      try {
        const rsp: any = await projectApi.createNewProjectResource(
          project_id,
          paramsForCreateNewProjectResource,
        );
        const code = rsp.response?.status;
        if (code >= 400) {
          const error = rsp?.response?.data?.message;
          errorApi.post(new Error(`${error}`));
          fine = false;
        }
        if (rsp?.moreData?.response) {
          const createdEnvs = [];
          rsp.moreData.response.forEach((item: any) => {
            if (
              item.status === 'fulfilled' &&
              (item.value.status === 200 || item.value.status === 201)
            ) {
              createdEnvs.push(item.value.data.environment);
            }
          });
          if (createdEnvs.length + 1 !== values.vaultEnvironments.length) {
            fine = false;
            errorApi.post(
              new Error(
                `We were only able to create resources for ${
                  createdEnvs.length + 1
                } environments`,
              ),
            );
          }
        }
        setNewResourceId(rsp.response?.data?.id);
      } catch (e) {
        errorApi.post(new Error(`${e.message}`));
        fine = false;
      }
    } else {
      const params: any = {
        idToken,
        project_id: Number(projectData.id),
        vault: values.vaultEnvironments,
      };
      const rsp: any = await projectApi.createNewVault(
        values.vault_namespace,
        params,
      );
      try {
        const code = rsp.response?.status;
        const errorCode = rsp.response[0]?.status;
        if (errorCode === 409) {
          const error = rsp?.response[0]?.data?.Error;
          errorApi.post(new Error(`${error}`));
          fine = false;
        } else if (code >= 400) {
          const error = rsp?.response?.data?.message;
          errorApi.post(new Error(`${error}`));
          fine = false;
        }
        setNewResourceId(rsp.response?.data?.id);
      } catch (e) {
        errorApi.post(new Error(`${e.message}`));
        fine = false;
      }
    }
    setApiLoader(false);
    if (fine) {
      handleDialogOpen();
    }
  };

  const onSubmitCreateArtifactory = async (values: any) => {
    setRClass(values.r_class);
    let fine = true;
    const project_id = projectData.id.toString();
    const params: any = {
      idToken,
      key: values.artifactory_repo_name.toLowerCase(),
      name: values.artifactory_repo_name,
      description: values.artifactory_description,
      dev_tool_id: Number(selectedResource),
    };
    if (values.package_type !== '' && values.r_class !== '') {
      params.config = {
        packageType: values.package_type,
        rclass: values.r_class,
      };
    }
    setApiLoader(true);
    try {
      const rsp: any = await projectApi.createNewProjectResource(
        project_id,
        params,
      );
      const code = rsp.response?.status;
      if (code === 401 || code === 404 || code === 400 || code === 500) {
        const error = rsp?.response?.data?.message;
        errorApi.post(new Error(`${error}`));
        fine = false;
      }
      setNewResourceId(rsp.response?.data?.id);
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
      fine = false;
    }
    setApiLoader(false);
    if (fine) {
      handleDialogOpen();
    }
  };

  const getParamConfigForArtifactorySaas = (values: any) => {
    const config: any = {
      packageType: values.package_type,
      rclass: values.r_class,
    };
    if (values.r_class === 'local') {
      config.sites = [...values.sites];
    }
    if (values.r_class === 'federated') {
      config.sites = ['jp', 'us'];
    }
    if (
      isAdmin &&
      (values.r_class === 'remote' || values.r_class === 'virtual')
    ) {
      config.sites = [...values.sites];
    }
    return config;
  };

  const onSubmitCreateArtifactorySaas = async (values: any) => {
    setRClass(values.r_class);
    let fine = true;
    const project_id = projectData.id.toString();
    const params: any = {
      idToken,
      key: values.artifactory_saas_repo_name.toLowerCase(),
      name: values.artifactory_saas_repo_name,
      description: values.artifactory_saas_description,
      dev_tool_id: Number(selectedResource),
    };
    if (values.package_type !== '' && values.r_class !== '') {
      params.config = getParamConfigForArtifactorySaas(values);
    }
    setApiLoader(true);
    try {
      const rsp: any = await projectApi.createNewProjectResource(
        project_id,
        params,
      );
      const code = rsp.response?.status;
      if (code === 401 || code === 404 || code === 400 || code === 500) {
        const error = rsp?.response?.data?.message;
        errorApi.post(new Error(`${error}`));
        fine = false;
      }
      setNewResourceId(rsp.response?.data?.id);
    } catch (e) {
      errorApi.post(new Error(`${e.message}`));
      fine = false;
    }
    setApiLoader(false);
    if (fine) {
      handleDialogOpen();
    }
  };

  const disableResourceRadioBtn = (resourceID: any) => {
    // disable radio button for non resource creator list if they're not admin
    if (!isProjectOwner(Number(projectId)) && !isAdmin) {
      return !isResourceCreator(Number(projectId), resourceID);
    }
    // other wise disable for the disabled devtools
    return !enabledDevTools.includes(resourceID);
  };

  const showComingSoonText = (resourceID: any) => {
    // show for the disabled devtools that are not in resource creator list
    return (
      ![...enabledDevTools].includes(resourceID) &&
      !isResourceCreator(Number(projectId), resourceID)
    );
  };

  const enableInfoText = () => {
    const data = resourcesList?.map((resource: any) =>
      disableResourceRadioBtn(resource.id),
    );
    return data.includes(true);
  };

  const onDescriptionClick = () => {
    setDescriptionDirty(true);
  };

  const resourceKeyClickHandler = () => setKeyDirty(true);

  const resourceNameClickHandler = () => setNameDirty(true);

  const displayForm = () => {
    switch (selectedResource) {
      case GITHUB_DEV_TOOL_ID.toString():
        return (
          !isGithubAtlassianStopped && (
            <CreateGithubResourceForm
              onResourceSubmit={onResourceSubmit}
              nameDirty={nameDirty}
              projectData={projectData}
              onResourceNameClick={resourceNameClickHandler}
              descriptionDirty
              onDescriptionClick={onDescriptionClick}
              handleCreateResource={handleCreateResource}
              apiLoader={apiLoader}
            />
          )
        );
      case GITHUBEMU_DEV_TOOL_ID.toString():
        return (
          <CreateGithubEMUResourceForm
            onResourceSubmit={onResourceSubmit}
            nameDirty={nameDirty}
            projectData={projectData}
            onResourceNameClick={resourceNameClickHandler}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            handleCreateResource={handleCreateResource}
            apiLoader={apiLoader}
          />
        );

      case CONFLUENCE_DEV_TOOL_ID.toString():
        return (
          <CreateConfluenceResourceForm
            onResourceSubmit={onResourceSubmit}
            nameDirty={nameDirty}
            projectData={projectData}
            onResourceNameClick={resourceNameClickHandler}
            keyDirty={keyDirty}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            apiLoader={apiLoader}
            handleCreateResource={handleCreateResource}
            onResourceKeyClick={resourceKeyClickHandler}
          />
        );
      case ARTIFACTORY_DEV_TOOL_ID.toString():
        return (
          !isGithubAtlassianStopped && (
            <CreateArtifactoryResourceForm
              onSubmitCreateArtifactory={onSubmitCreateArtifactory}
              artifactoryNameDirty={artifactoryNameDirty}
              projectData={projectData}
              onArtifactoryNameClick={() => setArtifactoryNameDirty(true)}
              descriptionDirty={descriptionDirty}
              onDescriptionClick={onDescriptionClick}
              apiLoader={apiLoader}
              packageType={packageType}
              handleCreateResource={handleCreateResource}
              userRole={isAdmin ? 'admin' : 'user'}
            />
          )
        );
      case MTFUJI_DEV_TOOL_ID.toString():
        return (
          <Grid item xs={12}>
            <Wizard stargateProjectId={parseInt(projectId, 10)} />
          </Grid>
        );
      case VAULT_DEV_TOOL_ID.toString():
        return (
          <CreateVaultResourceForm
            isVaultNewUIEnabled={isVaultNewUIEnabled}
            setIsVaultNewUIEnabled={setIsVaultNewUIEnabled}
            onSubmitCreateVault={onSubmitCreateVault}
            vaultNameDirty={vaultNameDirty}
            projectData={projectData}
            onVaultNameClick={() => setVaultNameDirty(true)}
            enviromentsData={enviromentsData}
            handleCreateResource={handleCreateResource}
            apiLoader={apiLoader}
            isDisabled={disableResourceRadioBtn(Number(selectedResource))}
          />
        );
      case JIRA_DEV_TOOL_ID.toString():
        return (
          <CreateJiraResourceForm
            onResourceSubmit={onResourceSubmit}
            keyDirty={keyDirty}
            projectData={projectData}
            onResourceKeyClick={resourceKeyClickHandler}
            nameDirty={nameDirty}
            onResourceNameClick={resourceNameClickHandler}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            projectTemplates={projectTemplates}
            handleCreateResource={handleCreateResource}
            apiLoader={apiLoader}
          />
        );

      case JAMA_DEV_TOOL_ID.toString():
        return (
          <CreateJamaResourceForm
            onResourceSubmit={onResourceSubmit}
            keyDirty={keyDirty}
            projectData={projectData}
            onResourceKeyClick={resourceKeyClickHandler}
            nameDirty={nameDirty}
            onResourceNameClick={resourceNameClickHandler}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            handleCreateResource={handleCreateResource}
            apiLoader={apiLoader}
          />
        );

      case ARTIFACTORY_SAAS_DEV_TOOL_ID.toString():
        return (
          <CreateArtifactorySaaSResourceForm
            onSubmitCreateArtifactorySaaS={onSubmitCreateArtifactorySaas}
            artifactoryNameDirty={artifactoryNameDirty}
            onArtifactoryNameClick={() => setArtifactoryNameDirty(true)}
            descriptionDirty={descriptionDirty}
            onDescriptionClick={onDescriptionClick}
            apiLoader={apiLoader}
            packageType={packageType}
            handleCreateResource={handleCreateResource}
            userRole={isAdmin ? 'admin' : 'user'}
          />
        );

      default:
        return false;
    }
  };

  return (
    <PageLayout
      type="entity"
      title={projectData?.name}
      headerAdditionalControls={<ProjectOwners owners={projectData?.owners} />}
      backToLink={backToLink}
    >
      {apiLoader ? <Progress /> : ''}
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} className={classes.createResourceItem}>
          <Typography
            variant="h3"
            className={classes.selectResourceText}
            id="create-resource-header"
          >
            Create Resource
          </Typography>
        </Grid>

        <Paper className={classes.selectResourceContainer}>
          <Grid container>
            <Grid item className={classes.selectResourceItem} xs={12}>
              <Typography
                variant="subtitle2"
                className={classes.selectResourceText}
              >
                Select Resource
              </Typography>

              {enableInfoText() && (
                <div className={classes.infoDiv}>
                  <InfoOutlinedIcon className={classes.infoIcon} />
                  <Typography variant="overline" paragraph>
                    <i>
                      You do not have permission to create 'few' resources in
                      this project workspace. Please ask a project owner to
                      grant you create resource permission
                    </i>
                    ,{' '}
                    <Link
                      className={classes.hyperlink}
                      target="_blank"
                      to={getTechDocsLink('delegate-user-mgmt')}
                    >
                      learn more
                    </Link>
                    .
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {resourcesList?.map((resource: any, index: any) => (
                  <span
                    key={index}
                    className={
                      disableResourceRadioBtn(resource.id)
                        ? classes.resourceGray
                        : classes.resourceList
                    }
                  >
                    <Radio
                      checked={
                        resource.id === Number(selectedResource) ? true : false
                      }
                      onChange={handleChange}
                      value={`${resource.id}`}
                      name={`${resource.id}`}
                      id={`${resource.id}`}
                      inputProps={{ 'aria-label': resource.id }}
                      disabled={disableResourceRadioBtn(resource.id)}
                    />
                    {transformResourceName(resource.name)}
                    &nbsp;
                    {showComingSoonText(resource.id) && (
                      <span className={classes.comingSoon}>
                        {resource.id !== GITHUBEMU_DEV_TOOL_ID ? (
                          '(Coming Soon)'
                        ) : (
                          <>
                            -{' '}
                            <Link
                              to="/news?id=EMU-migration-paused"
                              target="_blank"
                              className={classes.hyperLink}
                            >
                              temporarily suspended
                            </Link>
                          </>
                        )}
                      </span>
                    )}
                  </span>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Grid item xs={12} className={classes.displayFormContainer}>
          {displayForm()}
        </Grid>
      </Grid>
      <CustomDialogMapper
        dialogOpen={open}
        handleDialogClose={handleDialogClose}
        selectedResource={selectedResource}
        rClass={rClass}
      />
    </PageLayout>
  );
};

export const CreateResourcePage = () => {
  const { projectId } = useParams() as { projectId: string };
  return <CreateResourceComponent projectId={projectId} />;
};
