import { Config } from '@backstage/config';
import { NotFoundError } from '@backstage/errors';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { stringify as stringifyQueryString } from 'qs';
import { generateHeaders } from 'sg-utils-frontend';

export const projectApiRef = createApiRef<ProjectsApiInterface>({
  id: 'plugin.projects',
});

type ProjectsApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

type AllProjectRequestParams = {
  name?: string;
  owner_email?: string;
  ip_owner_company_id?: string;
  participant_company_id?: string;
  search_resources?: string;
  page?: string;
  size?: string;
  order_by?: string;
  order?: string;
  signal?: AbortSignal;
  //  include_archived?: boolean
};
type GetUserMembershipRequestData = {
  user_group_id?: string;
  project_id?: string;
  user_email?: string;
  include_other_emails_of_user?: boolean;
  requester_email?: string;
  reviewer_email?: string;
  status?: string;
  action?: string;
  sent?: string;
  process_state?: string;
  page?: string;
  size?: string;
  order_by?: string;
  order?: string;
};
type UserMembershipData = {
  idToken: string;
  request_ids: Array<number>;
};
type IRequestAccessData = {
  project_id: number;
  idToken: string;
  members: Array<string>;
  reason: string;
};

type CreateUserMembershipRequests = {
  idToken: string;
  user_memberships: any[];
  project_id: number;
};

export interface IProjectResourceData {
  idToken: string;
  key: string;
  name: string;
  description?: string;
  dev_tool_id: number;
  environments?: any[];
}

export interface ProjectResourceAsPerEnv {
  idToken: string;
  environments: any[];
}

export interface createVaultData {
  idToken: string;
  project_id: number;
  maintainer_group_id: string;
  developer_group_id: string;
}
enum filter {
  'jira',
  'confluence',
  'github',
  'ghe',
}
export interface ILicenseData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  filter: filter;
}
export interface IArtifactoryStorageData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
  order_by?: string;
  order?: string;
}
export interface IObsLogsData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
}
export interface IObsMetricsData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
}
export interface RowData {
  project_id: number;
  user_email: String;
  enable_self_service: boolean;
  url_link: String;
  instructions: String;
  created_by: String;
  created_on: Date;
  modified_on: Date;
  modified_by: String;
}
export interface INoCache {
  no_cache: string;
}

export interface upsertParams {
  user_email: String;
  enable_self_service: boolean;
  url_link: String;
  instructions: String;
}

export interface groupRestriction {
  allowed_company_id: Array<Number>;
}
export interface UpdateUserGroupBody {
  description: string;
  accessible_resources: Array<Number>;
  group_restriction: groupRestriction;
  allow_sharing_to_all: Boolean;
  shareable_allowed_projects: Array<Number>;
  group_managers: Array<Object>;
}

type addProjectUserGroup = {
  idToken: string;
  members: Array<string>;
  name: string;
  description?: string;
  accessible_resources: Array<number> | Array<{ id: number; role: string }>;
};
type GetUserUserGroupsSearchData = {
  search_term: string;
  start_index?: number;
  items_per_page?: number;
};

type GetProjectMembers = {
  startIndex?: number;
  count?: number;
  email_search_strings?: Array<string>;
  email_exclude_domains?: Array<string>;
  include_inactive?: boolean;
  order_by?: string;
};

type archiveProjectUserGroup = {
  id: string;
  idToken: string;
};

type archiveProjectResource = {
  idToken: string;
};

type updateProjectUserGroup = {
  id: string;
  description?: string;
  accessible_resources: Array<number> | Array<{ id: number; role: string }>;
  group_managers?: Array<{ user_email: string }>;
  group_restriction?: groupRestriction;
};
type EditProjectById = {
  idToken: string;
  name: string;
  description?: string;
  management_type: string;
  owners: Array<object>;
  ip_owner_companies: Array<object>;
  participant_companies: Array<object>;
};

type createProjectData = {
  name: string;
  key: string;
  description?: string;
  management_type: string;
  owners: Array<object>;
  ip_owner_companies: Array<object>;
  participant_companies: Array<object>;
};

type updateProjectResource = {
  name: string;
  key: string;
  description?: string;
};

type userQueryParams = {
  include?: string;
};
type IToken = {
  idToken: string;
};

type roleData = {
  roles: Array<{
    expired_on?: string | null;
    role: string;
  }>;
};

type artifactoryAccessData = {
  artifactory_name: string;
  artifact_repo_key: string;
};

type userGroupRoles = {
  op: string;
  value: Array<object>;
};

export interface GroupManagerData {
  resource_managers: Array<{
    user_email: string;
  }>;
}

export interface ILegalContractParamData {
  include_archived?: boolean;
  include_assigned_companies?: boolean;
  name?: string;
  no_cache?: boolean;
}

export interface ILegalContractData {
  name: string;
  contract_type: string;
  description?: string;
  contract_url: string;
  started_on: string;
  ended_on: string;
  assigned_company_ids: Array<number>;
}

export interface AssignUserGroupToResourceData {
  idToken: Object;
  userGroupRoles: Array<userGroupRoles>;
}

export interface CreatorRolesData {
  project_resource_creators?: Array<{
    op: string;
    value: Array<{
      dev_tool_id: number;
      resource_creators: Array<{
        user_email: string;
      }>;
    }>;
  }>;
  project_user_group_creators?: Array<{
    op: string;
    value: Array<{
      user_email: string;
    }>;
  }>;
}

export interface TagsOfEntityData {
  project_id_filters: Array<number>;
  entity_type_filters: Array<string>;
  tag_filters: Array<{
    tag_key: string;
    tag_values?: Array<string>;
  }>;
  include_tags_in_response?: boolean;
  page?: number;
  size?: number;
  order?: string;
  no_cache?: boolean;
}

export interface IGroupsOfResourceParamData {
  include_archived?: boolean;
  no_cache?: boolean;
}

export interface ProjectsApiInterface {
  getAllProjects(
    params: AllProjectRequestParams,
    idToken: string,
  ): Promise<Response>;
  getAllUserMembershipRequestsData(
    params: GetUserMembershipRequestData,
    idToken: Object,
  ): Promise<Response>;
  cancelUserMembership(params: UserMembershipData): Promise<Response>;
  approveUserMembership(params: UserMembershipData): Promise<Response>;
  rejectUserMembership(params: UserMembershipData): Promise<Response>;
  holdUserMembership(params: UserMembershipData): Promise<Response>;
  retryUserMembership(params: UserMembershipData): Promise<Response>;
  resumeUserMembership(params: UserMembershipData): Promise<Response>;
  getProjectByID(id: string, idToken: string, params: any): Promise<any>;
  getGroupByIDFromProject(
    id: string,
    projectId: string,
    idToken: Object,
  ): Promise<Response>;
  getProjectResources(id: any, params: any): Promise<Response>;
  getProjectResourceByID(id: any): Promise<Response>;
  getProjectUserGroupsByID(id: any): Promise<Response>;
  getProjectRequests(): Promise<Response>;
  getDevelopmentToolsData(idToken: Object): Promise<Response>;
  getUserGroups(id: string, idToken: string): Promise<Response>;
  getAttachableSourceProjects(id: string, idToken: String): Promise<Response>;
  getCompanyData(idToken: Object, paramsData?: Object): Promise<Response>;
  createUserMembershipRequestsV2Data(
    params: CreateUserMembershipRequests,
    projectId: string,
  ): Promise<Response>;
  requestAccess(params: IRequestAccessData): Promise<any>;
  createNewProjectResource(
    project_id: string,
    params: IProjectResourceData,
  ): Promise<Response>;
  createNewVault(nameSpace: string, params: createVaultData): Promise<Response>;
  addProjectUserGroupData(
    params: addProjectUserGroup,
    idToken: Object,
  ): Promise<Response>;
  archiveUserGroups(
    id: string,
    params: archiveProjectUserGroup,
  ): Promise<Response>;
  archiveProjectResource(
    id: string,
    resourceId: string,
    params: archiveProjectResource,
  ): Promise<Response>;
  updateUserGroups(
    id: string,
    idToken: string,
    paramsData: UpdateUserGroupBody,
  ): Promise<any>;
  updateProjectResource(
    pid: string,
    rid: string,
    paramsData: updateProjectResource,
  ): Promise<any>;
  getUserUserGroupsSearchData(params: any): Promise<any>;
  getUserGroupPerProject(id: any, userId: any): Promise<any>;
  editById(id: any, paramsData: EditProjectById): Promise<any>;
  createProject(paramsData: createProjectData, idToken: string): Promise<any>;
  getUserDetails(
    idToken: Object,
    queryParams?: userQueryParams,
  ): Promise<Response>;
  changeUserRole(params: roleData, idToken: string): Promise<any>;
  getUserRoles(idToken: string): Promise<any>;
  getUserMemberList(
    id: string,
    count: any,
    startIndex: any,
    idToken: any,
    groupId: any,
    orderBy: any,
    reverse: any,
  ): Promise<any>;
  unArchiveUserGroups(
    projectId: string,
    paramsData: archiveProjectUserGroup,
  ): Promise<any>;
  unArchiveResources(
    projectId: string,
    resourceId: number,
    paramsData: IToken,
  ): Promise<any>;
  attachUserGroup(
    idToken: Object,
    targetProjectId: string,
    userGroupId: string,
  ): Promise<any>;
  detachUserGroup(
    idToken: Object,
    targetProjectId: string,
    userGroupId: string,
  ): Promise<any>;
  updateUserGroup(
    targetProjectId: string,
    userGroupId: string,
    paramsData: updateProjectUserGroup,
  ): Promise<any>;
  getGroupManagers(userGroupId: string, idToken: Object): Promise<any>;
  getGitHubOrgWebhookSecret(idToken: string, orgName: string): Promise<any>;
  getProjectMembers(id: string, params: GetProjectMembers): Promise<any>;
  upsertOptOutData(projectId: number, params: any): Promise<RowData>;
  getOptOutData(project_id: number): Promise<RowData>;
  attachAllowableProjects(
    ug_id: string,
    idToken: string,
    params: INoCache,
  ): Promise<any>;
  getUserGroup(ug_id: string, idToken: string, params: INoCache): Promise<any>;
  attachDestinationProjects(
    ug_id: string,
    idToken: string,
    params: INoCache,
  ): Promise<any>;
  getProjectUserGroupData(
    project_id: string,
    ug_id: string,
    idToken: string,
    params: INoCache,
  ): Promise<any>;
  getArtifactoryAccessProperties(
    id: any,
    idToken: string,
    artifactory_data: artifactoryAccessData,
  ): Promise<any>;

  updateArtifactoryAccessProperties(
    id: any,
    idToken: string,
    artifactory_data: artifactoryAccessData,
    req_body: any,
  ): Promise<any>;

  getProjectLegalContracts(
    idToken: string,
    id: string,
    paramsData: ILegalContractParamData,
  ): Promise<any>;
  createProjectLegalContract(
    idToken: string,
    id: string,
    paramsData: ILegalContractData,
  ): Promise<any>;
  getProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
    paramsData: ILegalContractParamData,
  ): Promise<any>;
  updateProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
    paramsData: ILegalContractData,
  ): Promise<any>;
  archiveProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
  ): Promise<any>;
  assignUserGroupToResources(
    paramsData: AssignUserGroupToResourceData,
    targetProjectId: string,
    resourceId: string,
  ): Promise<any>;
  getUserGroupsOfResource(
    targetProjectId: string,
    resourceId: string,
    idToken: string,
    paramsData: IGroupsOfResourceParamData,
  ): Promise<any>;
  getAllUsersWithCreatorRoleInProject(
    project_id: string,
    idToken: string,
  ): Promise<any>;
  updateUsersCreatorRoleInProject(
    project_id: string,
    idToken: string,
    creator_roles_data: CreatorRolesData,
  ): Promise<any>;
  getGroupManagersOfUserGroup(
    project_id: string,
    resource_id: string,
    idToken: string,
  ): Promise<any>;
  addGroupManagers(
    project_id: string,
    resource_id: string,
    idToken: string,
    paramsData: GroupManagerData,
  ): Promise<any>;
  checkIfUserIsValid(
    project_id: string,
    user_email: string,
    idToken: string,
  ): Promise<any>;
  getUserGroupTags(
    project_id: string,
    user_group_id: string,
    idToken: string,
    tag?: string,
  ): Promise<any>;
  getTagsOfEntity(idToken: string, paramsData: TagsOfEntityData): Promise<any>;
  getServiceAuthToken(): Promise<any>;
  createNewProjectResourceAsPerEnv(
    projectId: number,
    resourceId: number,
    params: ProjectResourceAsPerEnv,
  ): Promise<any>;
}

export class ProjectsApi implements ProjectsApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private backendUrl: string;

  constructor(options: ProjectsApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  /**
   * Save project resource corresponding to project id
   * @param params as IProjectResourceData
   * @returns promise
   */
  public async createNewProjectResourceAsPerEnv(
    projectId: number,
    resourceId: number,
    params: ProjectResourceAsPerEnv,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${projectId}/resources/${resourceId}/environments`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  /**
   * Save project resource corresponding to project id
   * @param params as IProjectResourceData
   * @returns promise
   */
  public async createNewProjectResource(
    projectId: string,
    params: IProjectResourceData,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/createNewProjectResource/${projectId}`,
    );

    const newParams = JSON.parse(JSON.stringify(params));
    if (params.environments?.length) {
      newParams.environment = newParams.environments[0].environment;
      delete newParams.environments;
    }
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(newParams),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    if (data.response.status < 400) {
      let remainingData;
      if (params.environments?.length && params.environments.length > 1) {
        const remainingEnvironments = JSON.parse(
          JSON.stringify(params.environments),
        );
        remainingEnvironments.shift();
        if (data.response?.data?.id) {
          try {
            remainingData = await this.createNewProjectResourceAsPerEnv(
              Number(projectId),
              data.response.data.id,
              { idToken: params.idToken, environments: remainingEnvironments },
            );
          } catch (err) {
            throw new NotFoundError(
              'Unable to create the resource(s) for all the environments, please try again after some time',
            );
          }
        } else {
          throw new NotFoundError(
            'Unable to create the resource(s), please try again after some time',
          );
        }
      }

      if (remainingData) {
        data.moreData = remainingData;
      }
    }
    return data;
  }

  public async getProjectResources(id: any, params: any): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/getProjectResources/${id}/resources`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async updateProjectResource(
    projectId: string,
    resourceId: string,
    params: updateProjectResource,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/updateProjectResources/${projectId}/resources/${resourceId}`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async archiveProjectResource(
    id: string,
    resourceId: string,
    params: archiveProjectResource,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/archiveProjectResources/${id}/resources/${resourceId}`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }
    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async createNewVault(
    nameSpace: string,
    params: createVaultData,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/createVaultNamespace/${nameSpace}`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getUserDetails(idToken: IToken, queryParams?: userQueryParams) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = new URL(`${backendUrl}/api/projects/me`);
    requestUrl.search = stringifyQueryString(queryParams);

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken.idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error?.message);
    }

    // status 206 means it's only partial data content received from core and noren has timed out the request.
    if (response.status === 206) {
      throw new Error(
        'Get user details(/me) request is timed out. (Http code: 206)',
      );
    }

    if (!response.ok) {
      throw new Error(data.error?.message || data.response?.data?.message);
    }

    return data;
  }

  public async changeUserRole(params: roleData, idToken: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const url = `${backendUrl}/api/projects/me/roles`;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data?.error?.message || response.statusText}`);
    }

    return data;
  }

  public async getUserRoles(idToken: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const tokenData = await this.identityApi.getCredentials();
    const token = tokenData?.token;
    const url = `${backendUrl}/api/projects/me/roles`;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data?.error?.message || response.statusText}`);
    }

    return data;
  }

  public async getAllProjects(
    params: AllProjectRequestParams,
    idToken: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${backendUrl}/api/projects/all`);
    requestUrl.search = stringifyQueryString(params);

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      signal: params.signal,
    });
    const data = await response.json();
    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async createUserMembershipRequestsV2Data(
    params: CreateUserMembershipRequests,
    projectId: string,
  ): Promise<Response> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${projectId}/requests/user-memberships`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw data.error;
    }
    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getAllUserMembershipRequestsData(
    params: GetUserMembershipRequestData,
    idToken: Object,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/getAllUserMembershipRequests`,
    );
    requestUrl.search = stringifyQueryString(params);

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async requestAccess(params: IRequestAccessData) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${backendUrl}/api/projects/request-access`);
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async cancelUserMembership(params: UserMembershipData) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/cancelUserMemberships`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async approveUserMembership(params: UserMembershipData) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/approveUserMemberships`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async rejectUserMembership(params: UserMembershipData) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/rejectUserMemberships`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async holdUserMembership(params: UserMembershipData) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/holdUserMemberships`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async retryUserMembership(params: UserMembershipData) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/retryUserMemberships`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async resumeUserMembership(params: UserMembershipData) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/resumeUserMemberships`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getProjectByID(
    id: string,
    idToken: string,
    params: any,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/get/${encodeURIComponent(id)}`;

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (response.status === 403) {
      return {
        status: 403,
      };
    }

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getGroupByIDFromProject(
    id: string,
    projectId: string,
    idToken: Object,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/${encodeURIComponent(
      projectId,
    )}/group/${encodeURIComponent(id)}`;
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data?.error?.message || response.statusText}`);
    }

    if (data?.error) {
      throw new NotFoundError(data.error.message);
    }

    return data;
  }

  public async addProjectUserGroupData(
    params: addProjectUserGroup,
    projectId: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/addProjectUserGroup/${projectId}`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async getUserUserGroupsSearchData(
    params: GetUserUserGroupsSearchData,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/getGroups/users/search`,
    );
    requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getUserGroupPerProject(id: string, userId: Object) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${backendUrl}/api/projects/getGroups/${id}/groups/membership/${userId}`,
    );
    const response = await fetch(url.toString(), {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async getUserMemberList(
    id: string,
    count: any,
    startIndex: any,
    idToken: any,
    groupId: any,
    orderBy: any,
    reverse: any,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    let url = `${backendUrl}/api/projects/getUsersList/${id}`;
    const params = new URLSearchParams();
    params.set('count', count);
    params.set('startIndex', startIndex);
    params.set('order_by', orderBy);
    params.set('reverse', reverse);
    url += `?${params}`;

    const bodyData = {
      idToken: idToken,
      group_id: groupId,
    };

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(bodyData),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async archiveUserGroups(id: string, params: archiveProjectUserGroup) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/archiveUserGroups/${id}`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async unArchiveUserGroups(
    id: string,
    params: archiveProjectUserGroup,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/unArchiveUserGroups/${id}`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async unArchiveResources(
    projetId: string,
    resourceId: number,
    params: IToken,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/unArchivResources/${projetId}/resource/${resourceId}`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async updateUserGroups(
    id: string,
    idToken: string,
    params: UpdateUserGroupBody,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/updateUserGroups/${id}`,
    );
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getUserGroups(id: string, idToken: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/${encodeURIComponent(
      id,
    )}/usergroups`;

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(idToken),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }

  // TODO: add unit test
  public async getAttachableSourceProjects(id: string, idToken: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/${encodeURIComponent(
      id,
    )}/attachable-source-projects`;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(url, {
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data?.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async editById(id: string, params: EditProjectById) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${backendUrl}/api/projects/editById/${id}`);
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getProjectResourceByID(id: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/all/${encodeURIComponent(
      id,
    )}/resources`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async getProjectUserGroupsByID(id: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/all/${encodeURIComponent(
      id,
    )}/user-groups`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getUserGroupTags(
    project_id: string,
    group_id: string,
    idToken: string,
    tag?: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/${project_id}/user-groups/${group_id}/tags`;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const res = await response.json();
    if (tag && res?.data?.tags[tag]) {
      const requestedTag: any = { status: res?.status, data: { tags: {} } };
      requestedTag.data.tags[tag] = res?.data.tags[tag];
      return requestedTag;
    }

    return res;
  }

  public async getTagsOfEntity(idToken: string, params: TagsOfEntityData) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/tags/entities`;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });

    const data = await response.json();

    return data;
  }

  public async getServiceAuthToken() {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${backendUrl}/api/projects/service/auth/token`;
    const headers = generateHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message, data.error.code);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getProjectRequests() {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = `${backendUrl}/api/requests`;
    const response = await fetch(requestUrl, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async getDevelopmentToolsData(idToken: Object) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = `${backendUrl}/api/projects/getDevelopmentToolsData`;
    const response = await fetch(requestUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getCompanyData(idToken: Object, paramsData?: Object) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    let requestUrl = `${backendUrl}/api/projects/companies`;

    if (paramsData) {
      const searchParams = new URLSearchParams();
      Object.entries(paramsData).forEach(param => {
        const [key, value] = param;
        searchParams.set(key, value);
      });
      if (searchParams.toString()) {
        requestUrl += `?${searchParams}`;
      }
    }

    const response = await fetch(requestUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async createProject(params: createProjectData, idToken: string) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${backendUrl}/api/projects/createProject`);

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async attachUserGroup(
    idToken: Object,
    targetProjectId: string,
    userGroupId: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const url = new URL(
      `${backendUrl}/api/projects/${targetProjectId}/attachUserGroup/${userGroupId}`,
    );
    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });
    const data = await response.json();
    return data;
  }

  public async detachUserGroup(
    idToken: Object,
    targetProjectId: string,
    userGroupId: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const url = new URL(
      `${backendUrl}/api/projects/${targetProjectId}/detachUserGroup/${userGroupId}`,
    );
    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });
    const data = await response.json();
    return data;
  }

  public async updateUserGroup(
    targetProjectId: string,
    userGroupId: string,
    params: updateProjectUserGroup,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${targetProjectId}/updateUserGroup/${userGroupId}`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
  }
  public async assignUserGroupToResources(
    paramsData: AssignUserGroupToResourceData,
    targetProjectId: string,
    resourceId: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${targetProjectId}/resources/${resourceId}/user-groups`,
    );
    try {
      const response = await fetch(requestUrl.toString(), {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...(token && { Authorization: `Bearer ${token}` }),
        },
        body: JSON.stringify(paramsData),
      });
      const data = await response.json();

      if (data && data.error) {
        throw new Error(data.error.message);
      }

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return data;
    } catch (e) {
      return { status: 500, data: { message: e.message } };
    }
  }

  public async getUserGroupsOfResource(
    targetProjectId: string,
    resourceId: string,
    idToken: string,
    paramsData: IGroupsOfResourceParamData,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${targetProjectId}/resources/${resourceId}/user-groups`,
    );
    requestUrl.search = stringifyQueryString(paramsData);

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getGroupManagers(userGroupId: string, idToken: Object) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/getGroup/${userGroupId}/managers`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getGitHubOrgWebhookSecret(
    idToken: string,
    orgName: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;

    const backendUrl = this.configApi.getString('backend.baseUrl');
    const requestUrl = new URL(`${backendUrl}/api/projects/github/webhook/org`);

    const response = await fetch(requestUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify({
        id_token: idToken,
        org_name: orgName,
      }),
    });

    const data = await response.json();
    if (data && data.error) {
      if (data.reason) {
        throw new Error(data.reason);
      } else {
        throw new Error('Please try again.');
      }
    }
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getProjectMembers(
    id: string,
    params: GetProjectMembers,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const { token } = await this.identityApi.getCredentials();
    const requestUrl = new URL(`${backendUrl}/api/projects/${id}/members`);
    requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async upsertOptOutData(projectId: number, params: upsertParams) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const pluginId = 'selfServiceOptOutSettings';
    const requestUrl = new URL(
      `${backendUrl}/api/${pluginId}/settings/${projectId}/selfservice`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
  }

  public async getOptOutData(projectId: number) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const pluginId = 'selfServiceOptOutSettings';
    const requestUrl = new URL(
      `${backendUrl}/api/${pluginId}/settings/${projectId}/selfservice`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });

    const data = await response.json();
    return data;
  }

  public async attachAllowableProjects(
    ug_id: string,
    idToken: string,
    paramsData: INoCache,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/user-groups/${ug_id}/attach-allowable-projects`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();
    return data;
  }

  public async getUserGroup(
    ug_id: string,
    idToken: string,
    paramsData: INoCache,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/user-groups/${ug_id}`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async attachDestinationProjects(
    ug_id: string,
    idToken: string,
    paramsData: INoCache,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/user-groups/${ug_id}/attach-destination-projects`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async getProjectUserGroupData(
    project_id: string,
    ug_id: string,
    idToken: string,
    paramsData: INoCache,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${project_id}/user-groups/${ug_id}`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async getProjectLegalContracts(
    idToken: string,
    id: string,
    paramsData: ILegalContractParamData,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(`${backendUrl}/api/projects/${id}/legal-contracts`);

    url.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async createProjectLegalContract(
    idToken: string,
    id: string,
    paramsData: ILegalContractData,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(`${backendUrl}/api/projects/${id}/legal-contracts`);

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(paramsData),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
    paramsData: ILegalContractParamData,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${backendUrl}/api/projects/${id}/legal-contracts/${contractId}`,
    );

    url.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async updateProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
    paramsData: ILegalContractData,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${backendUrl}/api/projects/${id}/legal-contracts/${contractId}`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(paramsData),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async archiveProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${backendUrl}/api/projects/${id}/legal-contracts/${contractId}/archive`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getArtifactoryAccessProperties(
    id: string,
    idToken: string,
    artifactory_data: artifactoryAccessData,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/artifactory-access/properties/${id}/${artifactory_data.artifactory_name}/${artifactory_data.artifact_repo_key}`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();
    return data;
  }

  public async updateArtifactoryAccessProperties(
    id: string,
    idToken: string,
    artifactory_data: artifactoryAccessData,
    req_body: any,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/artifactory-access/edit/properties/${id}/${artifactory_data.artifactory_name}/${artifactory_data.artifact_repo_key}`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(req_body),
    });

    const data = await response.json();
    return data;
  }

  public async getAllUsersWithCreatorRoleInProject(
    project_id: string,
    idToken: string,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${project_id}/roles/creators`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();
    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async updateUsersCreatorRoleInProject(
    project_id: string,
    idToken: string,
    creator_roles_data: CreatorRolesData,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${project_id}/roles/creators`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'PATCH',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(creator_roles_data),
    });

    const data = await response.json();
    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getGroupManagersOfUserGroup(
    project_id: string,
    resource_id: string,
    idToken: string,
  ) {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${project_id}/resources/${resource_id}/managers`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }

  public async addGroupManagers(
    project_id: string,
    resource_id: string,
    idToken: string,
    paramsData: GroupManagerData,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${backendUrl}/api/projects/${project_id}/resources/${resource_id}/managers`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(paramsData),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }

  public async checkIfUserIsValid(
    project_id: string,
    user_email: string,
    idToken: string,
  ): Promise<any> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${project_id}/users/${user_email}/valid`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'HEAD',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.status;
  }
}
