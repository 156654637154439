import React from 'react';
import { Grid, List, makeStyles, Typography } from '@material-ui/core';

import { CatalogSearchResultListItem } from '@internal/plugin-catalog-fork';
import { SearchType } from '@backstage/plugin-search';

import { PageLayout, Link } from '@internal/sg-ui-kit';
import {
  SearchBar,
  SearchResult,
  SearchResultPager,
} from '@backstage/plugin-search-react';
import { Content, WarningPanel } from '@backstage/core-components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { DEFAULT_DEBOUNCE_TIME, SEARCH_INPUT_MAX_LENGTH } from 'usg-types';
import { searchResultsData } from 'sg-utils-frontend';

interface DocumentType {
  type: string;
  document: any;
  duplicates: any;
}

const useStyles = makeStyles(() => ({
  searchBox: {
    border: '2px solid #0E4295',
    borderRadius: '15px',
    padding: '5px',
  },
  infoBox: {
    marginTop: '5px',
    padding: '5px',
  },
  chevronRightIcon: {
    marginTop: '2px',
  },
  searchItemSection: {
    minHeight: 'calc(100vh - 305px)',
  },
}));
const SearchPage = () => {
  const classes = useStyles();
  const [showResourceInfo] = React.useState(true);

  return (
    <PageLayout
      title="Search Stargate"
      subtitle="All content across Stargate"
      headerBackgroundImg="assets/welcome_bg.webp"
    >
      <Content className={classes.searchItemSection}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <div className={classes.searchBox}>
              <SearchBar
                debounceTime={DEFAULT_DEBOUNCE_TIME}
                id="search-bar-text-field"
                placeholder={`Search in Stargate (Max ${SEARCH_INPUT_MAX_LENGTH} characters)`}
                inputProps={{ maxLength: SEARCH_INPUT_MAX_LENGTH }}
              />
            </div>
            {showResourceInfo ? (
              <div className={classes.infoBox}>
                <WarningPanel
                  severity="info"
                  title="Looking for a GitHub org, Confluence space, Jira project or other project resource?"
                  defaultExpanded
                >
                  <Link
                    to="/projects"
                    icon={
                      <ChevronRightIcon className={classes.chevronRightIcon} />
                    }
                    spacing={0}
                  >
                    <Typography variant="subtitle1">
                      Project resource search by URL is available through the
                      projects page under "Advanced search"
                    </Typography>
                  </Link>
                </WarningPanel>
              </div>
            ) : null}
            <SearchType.Tabs
              types={[
                {
                  value: 'techdocs',
                  name: 'Documentation',
                },
                {
                  value: 'news',
                  name: 'News & Updates',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchResult>
              {({ results }) => (
                <List>
                  {searchResultsData(results).map(
                    ({ type, document, duplicates }: DocumentType) => {
                      switch (type) {
                        case 'service-catalog':
                          return null;
                        default:
                          return (
                            <CatalogSearchResultListItem
                              key={document.location}
                              result={document}
                              type={type}
                              duplicates={duplicates}
                              section="main"
                            />
                          );
                      }
                    },
                  )}
                </List>
              )}
            </SearchResult>
            <SearchResultPager />
          </Grid>
        </Grid>
      </Content>
    </PageLayout>
  );
};

export const searchPage = <SearchPage />;
