import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  identityApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';

import { catalogItemRouteRef, rootRouteRef, rootRouteRefV2 } from './routes';
import { ActionsCatalogApi, actionsCatalogApiRef } from './api';

export const actionsCatalogPlugin = createPlugin({
  id: 'actions-catalog',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: actionsCatalogApiRef,
      deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        gacApi: microsoftAuthApiRef,
      },
      factory: ({ configApi, identityApi, gacApi }) =>
        new ActionsCatalogApi({ configApi, identityApi, gacApi }),
    }),
  ],
});

export const ActionsCatalogPage = actionsCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ActionsCatalogPage',
    component: () =>
      import('./pages/ActionsCatalogPage').then(m => m.ActionsCatalogPage),
    mountPoint: rootRouteRef,
  }),
);

export const ActionsCatalogPageV2 = actionsCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ActionsCatalogPageV2',
    component: () =>
      import('./pages/ActionsCatalogPage').then(m => m.ActionsCatalogPageV2),
    mountPoint: rootRouteRefV2,
  }),
);

export const ActionsCatalogItemPage = actionsCatalogPlugin.provide(
  createRoutableExtension({
    name: 'ActionsCatalogItemPage',
    component: () =>
      import('./pages/ActionsCatalogItemPage').then(
        m => m.ActionsCatalogItemPage,
      ),
    mountPoint: catalogItemRouteRef,
  }),
);
